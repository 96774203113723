import React, {useEffect} from 'react'

import styles from './IdealStressLevel.module.css'
import GreenHeartImage from '../../images/green-heart.png'
import TrophyImage from '../../images/trophy.png'
import BicepsImage from '../../images/biceps.png'
import ThumbImage from '../../images/thumbs-up.png'

import { addToQuizForIdealStressLevel, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const IdealStressLevel = () => {
    const idealStressLevel = useSelector((state) => state.quiz.idealStressLevel)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(13))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        setTimeout(() => {
            window.scrollTo(0,9999);
        }, 150);
        
        dispatch(addToQuizForIdealStressLevel(quizTypeSelected))
    }

    function onContinueClicked(e){
        
        setTimeout(() => {
            navigate('/quiz/current-age' + queryParams)
        }, 500);
    }

    

    return (
        <div className={styles.activity__level__wrap}>
            <h1 className={styles.sec__title}>What would you consider your ideal stress level?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${idealStressLevel === 'Almost Non-Existent' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Almost Non-Existent')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.medium__text}>
                            Almost Non-Existent
                        </div>
                        <div className={styles.small__text}>
                            I want to be completely calm and relaxed most of the time
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${idealStressLevel === 'Minimal' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Minimal')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.medium__text}>
                            Minimal
                        </div>
                        <div className={styles.small__text}>
                            Occasional stress is okay, but I'd prefer to be relaxed most of the time
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${idealStressLevel === 'Moderate' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Moderate')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.medium__text}>
                            Moderate
                        </div>
                        <div className={styles.small__text}>
                            I'm okay with some stress as long as I can handle it
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${idealStressLevel === 'High' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'High')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.medium__text}>
                            High
                        </div>
                        <div className={styles.small__text}>
                            I thrive on stress and want to better manage it
                        </div>
                    </div>
                </div>
            </div>



            {
                idealStressLevel === 'Almost Non-Existent' ?
                <div className={`${styles.bmi__note__wrap} ${styles.success}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={GreenHeartImage} alt="GreenHeartImage" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}><span>HEALTH BENEFITS:</span> Reduce stress by 90%</div>
                        <div className={styles.bmi__note__sub__title}>There’s scientific evidence that significant stress reduction improves overall health: improved focus and concentration, better mood, enhanced immune function, and reduced blood pressure. Achieving near-zero stress levels can elevate these benefits significantly.</div>
                    </div>
                </div> : 
                ''
            }

            {
                idealStressLevel === 'Minimal' ?
                <div className={`${styles.bmi__note__wrap} ${styles.moderate}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={TrophyImage} alt="TrophyImage" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}><span className={styles.moderate__highlight}>MODERATE GOAL: </span> Reduce stress by 60%</div>
                        <div className={styles.bmi__note__sub__title}>Reducing stress by more than half can bring significant improvements to your mental health. According to a study by the American Psychological Association, reducing stress by this amount can help decrease symptoms of depression and anxiety, improve sleep, and enhance overall well-being.</div>
                    </div>
                </div> : 
                ''
            }

            {
                idealStressLevel === 'Moderate' ?
                <div className={`${styles.bmi__note__wrap} ${styles.moderate}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={BicepsImage} alt="BicepsImage" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}><span className={styles.moderate__highlight}>BALANCED LIFESTYLE </span> Reduce stress by 40%</div>
                        <div className={styles.bmi__note__sub__title}>Maintaining some level of stress can be beneficial as it helps to stay motivated and productive. However, reducing excessive stress can still lead to improved mood, better focus, and a healthier lifestyle. A 40% reduction in perceived stress can be a good balance.</div>
                    </div>
                </div> : 
                ''
            }

            {
                idealStressLevel === 'High' ?
                <div className={`${styles.bmi__note__wrap} ${styles.success}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={ThumbImage} alt="ThumbImage" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>THRIVING ON STRESS: Better Stress Management</div>
                        <div className={styles.bmi__note__sub__title}>For those who thrive on stress, the key is effective stress management. Studies from Mayo Clinic show that mindfulness and meditation can help you manage stress better, leading to improved cognitive function, emotional regulation, and even physical health.</div>
                    </div>
                </div> : 
                ''
            }
            

            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn} disabled={idealStressLevel.length <= 0}
                 onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default IdealStressLevel