import React, { useEffect } from 'react'

import styles from './DifficultyPiling.module.css'
import ExclaimImage from '../../images/exclaim.png'
import DoubleExclaimImage from '../../images/double-exclamation.png'

import { addCurrentStep, addToQuizForDifficultyPiling, addToQuizForPssScoreForQuestionTwo } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const DifficultyPiling = () => {

    const difficultyPiling = useSelector((state) => state.quiz.difficultyPiling)

    const pssScoreOne = useSelector((state) => state.quiz.pssScoreOne)
    const pssScoreTwo = useSelector((state) => state.quiz.pssScoreTwo)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(12))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])


    function onGoalSelected(e, quizTypeSelected, pssScore){
        window.scrollTo(0,9999);
        dispatch(addToQuizForDifficultyPiling(quizTypeSelected))
        dispatch(addToQuizForPssScoreForQuestionTwo(pssScore))
    }

    function onContinueClicked(e){
        setTimeout(() => {
            navigate('/quiz/ideal-stress-level' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.difficultyPiling__wrap}>
            <h1 className={styles.sec__title}>Over the last month, how often have you felt difficulties were piling up so high that you could not overcome them?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${difficultyPiling === 'Never' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Never', 0)}}>
                    <div className={styles.goal__item__title}>
                        Never
                    </div>
                </div>

                <div className={`${styles.goal__item} ${difficultyPiling === 'Almost Never' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Almost Never', 5)}}>
                    <div className={styles.goal__item__title}>
                        Almost Never
                    </div>
                </div>

                <div className={`${styles.goal__item} ${difficultyPiling === 'Sometimes' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Sometimes', 10)}}>
                    <div className={styles.goal__item__title}>
                        Sometimes
                    </div>
                </div>

                <div className={`${styles.goal__item} ${difficultyPiling === 'Fairly Often' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Fairly Often', 15)}}>
                    <div className={styles.goal__item__title}>
                        Fairly Often
                    </div>
                </div>

                <div className={`${styles.goal__item} ${difficultyPiling === 'Very Often' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Very Often', 20)}}>
                    <div className={styles.goal__item__title}>
                        Very Often
                    </div>
                </div>
            </div>



            {
                difficultyPiling === '' ?
                <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={ExclaimImage} alt="" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Calculating your Perceived Stress Score (PSS)</div>
                        <div className={styles.bmi__note__sub__title}>PSS is the most widely used psychological instrument for measuring factor for development of or the prevalence of several health issues.</div>
                    </div>
                </div> : 
                ''
            }


            {
                difficultyPiling === 'Never' ?
                <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={ExclaimImage} alt="" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Your perceived stress score is {+pssScoreOne + +pssScoreTwo}, which is considered moderate</div>
                        <div className={styles.bmi__note__sub__title}>You should try to focus on reducing your stress. We will use your score to tailor a personal program for stress reduction and mindfulness.</div>
                    </div>
                </div> : 
                ''
            }

            {
                difficultyPiling === 'Almost Never' ?
                <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={ExclaimImage} alt="" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Your perceived stress score is {+pssScoreOne + +pssScoreTwo}, which is considered moderate</div>
                        <div className={styles.bmi__note__sub__title}>You should try to focus on reducing your stress. We will use your score to tailor a personal program for stress reduction and mindfulness.</div>
                    </div>
                </div> : 
                ''
            }

            {
                difficultyPiling === 'Sometimes' ?
                <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={ExclaimImage} alt="" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Your perceived stress score is {+pssScoreOne + +pssScoreTwo}, which is considered high</div>
                        <div className={styles.bmi__note__sub__title}>You should try to focus on reducing your stress. We will use your score to tailor a personal program for stress reduction and mindfulness.</div>
                    </div>
                </div> : 
                ''
            }

            {
                difficultyPiling === 'Fairly Often' ?
                <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={DoubleExclaimImage} alt="" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Your perceived stress score is {+pssScoreOne + +pssScoreTwo}, which is considered high</div>
                        <div className={styles.bmi__note__sub__title}>You should try to focus on reducing your stress. We will use your score to tailor a personal program for stress reduction and mindfulness.</div>
                    </div>
                </div> : 
                ''
            }

            {
                difficultyPiling === 'Very Often' ?
                <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={DoubleExclaimImage} alt="" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Your perceived stress score is {+pssScoreOne + +pssScoreTwo}, which is considered high</div>
                        <div className={styles.bmi__note__sub__title}>You should try to focus on reducing your stress. We will use your score to tailor a personal program for stress reduction and mindfulness.</div>
                    </div>
                </div> : 
                ''
            }

                
            
            

            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn} disabled={difficultyPiling.length <= 0}
                 onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default DifficultyPiling