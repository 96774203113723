import React, { useEffect } from 'react'

import styles from './StatementMultitask.module.css'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { addToQuizForStatementMultitask, addCurrentStep } from '../../reducers/quiz'

const StatementMultitask = () => {
    const statementMultitask = useSelector((state) => state.quiz.statementMultitask)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(22))
    });

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForStatementMultitask(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/statement-distraction' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.number__rating__wrap}>
            <h1 className={styles.sec__title}>“I often find myself doing multiple things at once when I'm trying to relax or focus on self-care (checking emails, scrolling through social media, watching TV).”</h1>
            <h1 className={styles.sec__sub__title}>Does the statement above describe you?</h1>

            <div className={styles.number__rating__container}>
                <div className={styles.number__rating}>
                    <div className={`${styles.unit__rate} ${statementMultitask === '1' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '1')}}>1</div>
                    <div className={`${styles.unit__rate} ${statementMultitask === '2' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '2')}}>2</div>
                    <div className={`${styles.unit__rate} ${statementMultitask === '3' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '3')}}>3</div>
                    <div className={`${styles.unit__rate} ${statementMultitask === '4' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '4')}}>4</div>
                    <div className={`${styles.unit__rate} ${statementMultitask === '5' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '5')}}>5</div>
                </div>
                <div className={styles.number__rating__label}>
                    <div>Not at all</div>
                    <div>Yes, definitely</div>
                </div>
            </div>
        </div>
    )
}

export default StatementMultitask