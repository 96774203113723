import React, { useEffect } from 'react'

import styles from './MindStateYouWant.module.css'

import StressFreeImage from '../../images/StressFreeImage.png'
import ProductiveImage from '../../images/ProductiveImage.png'
import ConfidentImage from '../../images/ConfidentImage.png'
import BalancedImage from '../../images/BalancedImage.png'

import StressFreeMaleImage from '../../images/StressFreeMaleImage.png'
import ProductiveMaleImage from '../../images/ProductiveMaleImage.png'
import ConfidentMaleImage from '../../images/ConfidentMaleImage.png'
import BalancedMaleImage from '../../images/BalancedMaleImage.png'

import { addToQuizForMindStateYouWant, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const MindStateYouWant = () => {

    const mindStateYouWant = useSelector((state) => state.quiz.mindStateYouWant)
    const gender = useSelector((state) => state.quiz.gender)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(3))
    });

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForMindStateYouWant(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/meditation-practices' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.mind__state__you__want__wrap}>
            <h1 className={styles.sec__title}>Choose the mental state you most want to have</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${mindStateYouWant === 'Relaxed & Stress-Free' ? styles.active : ''}`} 
                onClick={ e => {onGoalSelected(e, 'Relaxed & Stress-Free')}}>
                    <div className={styles.goal__item__title}>
                        Relaxed & Stress-Free
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? StressFreeImage : StressFreeMaleImage} alt="Relaxed & Stress-Free" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${mindStateYouWant === 'Focused & Productive' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'Focused & Productive')}}>
                    <div className={styles.goal__item__title}>
                        Focused & Productive
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? ProductiveImage : ProductiveMaleImage} alt="Focused & Productive" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${mindStateYouWant === 'Confident & Socially Engaging' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'Confident & Socially Engaging')}}>
                    <div className={styles.goal__item__title}>
                        Confident & Socially Engaging
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? ConfidentImage : ConfidentMaleImage} alt="Confident & Socially Engaging" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${mindStateYouWant === 'Balanced & Emotionally Resilient' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'Balanced & Emotionally Resilient')}}>
                    <div className={styles.goal__item__title}>
                        Balanced & Emotionally Resilient
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? BalancedImage : BalancedMaleImage} alt="Balanced & Emotionally Resilient" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MindStateYouWant