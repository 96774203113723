import React, {useEffect} from 'react'

import styles from './LastFeltPeace.module.css'
import PerfectWeightImage from '../../images/lastfeltpeace.png'
import PerfectWeightMaleImage from '../../images/lastfeltpeacemale.png'

import { addToQuizForLastFeltPeace, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const LastFeltPeace = () => {

    const lastFeltPeace = useSelector((state) => state.quiz.lastFeltPeace)

    const gender = useSelector((state) => state.quiz.gender)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(5))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForLastFeltPeace(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/mindfulness-practice-level' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.lastfeltpeace__wrap}>
            <h1 className={styles.sec__title}>When was the last time you felt at peace and in full control of your mind?</h1>

            <div className={styles.perfect__weight}>
                <div className={styles.perfect__weight__img}>
                    <img src={gender === 'female' ? PerfectWeightImage : PerfectWeightMaleImage} alt="Perfect Weight" />
                </div>
                <div className={styles.goals__list}>
                    <div className={`${styles.goal__item} ${lastFeltPeace === '0-6 months ago' ? styles.active : ''}`}
                     onClick={ e => { onGoalSelected(e, '0-6 months ago')}}>
                        <div className={styles.goal__item__title}>
                            0-6 months ago
                        </div>
                    </div>
                    <div className={`${styles.goal__item} ${lastFeltPeace === '7-12 months ago' ? styles.active : ''}`}
                     onClick={ e => { onGoalSelected(e, '7-12 months ago')}}>
                        <div className={styles.goal__item__title}>
                            7-12 months ago
                        </div>
                    </div>
                    <div className={`${styles.goal__item} ${lastFeltPeace === '1-3 years ago' ? styles.active : ''}`}
                     onClick={ e => { onGoalSelected(e, '1-3 years ago')}}>
                        <div className={styles.goal__item__title}>
                            1-3 years ago
                        </div>
                    </div>
                    <div className={`${styles.goal__item} ${lastFeltPeace === 'More than 3 years ago' ? styles.active : ''}`}
                     onClick={ e => { onGoalSelected(e, 'More than 3 years ago')}}>
                        <div className={styles.goal__item__title}>
                            More than 3 years ago
                        </div>
                    </div>
                    <div className={`${styles.goal__item} ${lastFeltPeace === `I can't remember ever feeling this way` ? styles.active : ''}`}
                     onClick={ e => { onGoalSelected(e, `I can't remember ever feeling this way`)}}>
                        <div className={styles.goal__item__title}>
                            I can't remember ever feeling this way
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LastFeltPeace