import React, { useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { addToQuizForGoal } from '../../reducers/quiz'
import { addCurrentStep } from '../../reducers/quiz'

import styles from './MotivateGoal.module.css'
import BeatAnxietyImage from '../../images/BeatAnxiety.png'
import SleepBetterImage from '../../images/SleepBetter.png'
import QuitRacingThoughtsImage from '../../images/QuitRacingThoughts.png'
import RelieveDailyStressImage from '../../images/RelieveDailyStress.png'

import BeatAnxietyMaleImage from '../../images/BeatAnxietyMale.png'
import SleepBetterMaleImage from '../../images/SleepBetterMale.png'
import QuitRacingThoughtsMaleImage from '../../images/QuitRacingThoughtsMale.png'
import RelieveDailyStressMaleImage from '../../images/RelieveDailyStressMale.png'

const MotivateGoal = () => {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";

    const goal = useSelector((state) => state.quiz.goal)
    const gender = useSelector((state) => state.quiz.gender)
    
    const dispatch = useDispatch()

    const navigate = useNavigate()


    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForGoal(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/current-mind-state' + queryParams)
        }, 500);
    }
    

    useEffect(() => {
        dispatch(addCurrentStep(1))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])


    return (
        <div className={styles.goal__wrap}>
            <h1 className={styles.sec__title}>What is your goal?</h1>
            <h2 className={styles.sec__sub__title}>Please select your first priority.</h2>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${goal === 'Beat Anxiety' ? styles.active : ''}`} 
                onClick={ e => {onGoalSelected(e, 'Beat Anxiety')}}>
                    <div className={styles.goal__item__title}>
                        Beat Anxiety
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? BeatAnxietyImage : BeatAnxietyMaleImage} alt="Beat Anxiety" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${goal === 'Sleep Better' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'Sleep Better')}}>
                    <div className={styles.goal__item__title}>
                        Sleep Better
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? SleepBetterImage : SleepBetterMaleImage} alt="Sleep Better" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${goal === 'Quit Racing Thoughts' ? styles.active : ''}`} 
                onClick={ e => {onGoalSelected(e, 'Quit Racing Thoughts')}}>
                    <div className={styles.goal__item__title}>
                        Quit Racing Thoughts
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? QuitRacingThoughtsImage : QuitRacingThoughtsMaleImage} alt="Quit Racing Thoughts" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${goal === 'Relieve Daily Stress' ? styles.active : ''}`} 
                onClick={ e => {onGoalSelected(e, 'Relieve Daily Stress')}}>
                    <div className={styles.goal__item__title}>
                        Relieve Daily Stress
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? RelieveDailyStressImage : RelieveDailyStressMaleImage} alt="Relieve Daily Stress" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MotivateGoal