import React, { useEffect } from 'react'

import styles from './StatementImmediateProblem.module.css'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { addToQuizForStatementImmediateProblem, addCurrentStep } from '../../reducers/quiz'

const StatementImmediateProblem = () => {
    const statementImmediateProblem = useSelector((state) => state.quiz.statementImmediateProblem)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(21))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForStatementImmediateProblem(quizTypeSelected))

        if(quizTypeSelected === '4' || quizTypeSelected === '5'){
            setTimeout(() => {
                navigate('/quiz/mid-twenty-one' + queryParams)
            }, 500);
        } else {
            setTimeout(() => {
                navigate('/quiz/statement-multitask' + queryParams)
            }, 500);
        }
    }

    return (
        <div className={styles.number__rating__wrap}>
            <h1 className={styles.sec__title}>“I often find myself lost in thought or worry, even when there's no immediate problem at hand.”</h1>
            <h1 className={styles.sec__sub__title}>Does the statement above describe you?</h1>

            <div className={styles.number__rating__container}>
                <div className={styles.number__rating}>
                    <div className={`${styles.unit__rate} ${statementImmediateProblem === '1' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '1')}}>1</div>
                    <div className={`${styles.unit__rate} ${statementImmediateProblem === '2' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '2')}}>2</div>
                    <div className={`${styles.unit__rate} ${statementImmediateProblem === '3' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '3')}}>3</div>
                    <div className={`${styles.unit__rate} ${statementImmediateProblem === '4' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '4')}}>4</div>
                    <div className={`${styles.unit__rate} ${statementImmediateProblem === '5' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '5')}}>5</div>
                </div>
                <div className={styles.number__rating__label}>
                    <div>Not at all</div>
                    <div>Yes, definitely</div>
                </div>
            </div>
        </div>
    )
}

export default StatementImmediateProblem