import React, { useEffect } from 'react'

import styles from './TypicalSleep.module.css'
import OneImage from '../../images/one.png'

import SleepingImage from '../../images/Sleepingimage.png'
import SleepingMaleImage from '../../images/SleepingMaleimage.png'

import { addCurrentStep, addToQuizForTypicalSleep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const TypicalSleep = () => {

    const typicalSleep = useSelector((state) => state.quiz.typicalSleep)
    const gender = useSelector((state) => state.quiz.gender)


    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(16))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])


    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForTypicalSleep(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/falling-asleep' + queryParams)
        }, 500);
    }

    return (
        <div>
            <div className={styles.typicalSleep__wrap}>
                <h1 className={styles.sec__title}>How much sleep do you typically get per night?</h1>

                <div className={styles.goals__list}>
                    <div className={`${styles.goal__item} ${typicalSleep === 'Fewer than 5 hours' ? styles.active : ''}`}
                    onClick={e => {onGoalSelected(e, 'Fewer than 5 hours')}}>
                        <div className={styles.goal__item__title}>
                            Fewer than 5 hours
                        </div>
                    </div>

                    <div className={`${styles.goal__item} ${typicalSleep === 'Between 5 and 6 hours' ? styles.active : ''}`}
                    onClick={e => {onGoalSelected(e, 'Between 5 and 6 hours')}}>
                        <div className={styles.goal__item__title}>
                            Between 5 and 6 hours
                        </div>
                    </div>

                    <div className={`${styles.goal__item} ${typicalSleep === 'Between 7 and 8 hours' ? styles.active : ''}`}
                    onClick={e => {onGoalSelected(e, 'Between 7 and 8 hours')}}>
                        <div className={styles.goal__item__title}>
                            Between 7 and 8 hours
                        </div>
                    </div>

                    <div className={`${styles.goal__item} ${typicalSleep === 'More than 8 hours' ? styles.active : ''}`}
                    onClick={e => {onGoalSelected(e, 'More than 8 hours')}}>
                        <div className={styles.goal__item__title}>
                            More than 8 hours
                        </div>
                    </div>
                </div>



                <div className={styles.bmi__note__wrap}>
                    <div className={styles.bmi__note__img}>
                        <img src={OneImage} alt="" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>We ask about your sleep habits to customize your meditation plan</div>
                        <div className={styles.bmi__note__sub__title}>Whether you're struggling with insomnia or looking to enhance the quality of your rest, knowing your sleep patterns helps us suggest suitable meditation practices. If you're not getting enough sleep, for example, our tailored plan may include bedtime meditations or mindfulness techniques specifically aimed at improving sleep.</div>
                    </div>
                </div>
            </div>

            <div className={styles.sleep__bg} style={{ backgroundImage: gender === 'female' ? `url(${SleepingImage})` :  `url(${SleepingMaleImage})`}}>
            </div>
        </div>
    )
}

export default TypicalSleep