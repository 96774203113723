import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // showLogin: false,
  showQuizHeader: true,
  currentStep: '',
  quizReferenceId: '',
  gender: '',
  goal: '',
  currentMindState: '',
  mindStateYouWant: '',
  meditationPractices: [],
  lastFeltPeace: '',
  mindfulnessPracticeLevel: '',
  seekMeditationBenefit: [],
  handleTrafficSituation: '',
  improveMentalWellbeing: '',
  selfCareTiming: '',
  controlImportantThings: '',
  difficultyPiling: '',
  idealStressLevel: '',
  currentAge: '',
  differentFeels: '',
  typicalSleep: '',
  fallingAsleep: '',
  screenTime: '',
  handleStressSituation: '',
  stressReductionPlan: '',
  statementImmediateProblem: '',
  statementMultitask: '',
  statementDistraction: '',
  statementOldHabits: '',
  statementOverwhelm: '',
  quizEmail: '',
  pssScoreOne:'',
  pssScoreTwo:'',
  pssStrength: ''
}

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    // isShowLogin: (state, action) => {
    //   state.showLogin = action.payload
    // },
    isShowQuizHeader: (state, action) => {
      state.showQuizHeader = action.payload
    },
    addToQuizForQuizReferenceId: (state, action) =>{
      state.quizReferenceId = action.payload
      console.log('quiz ref ' + action.payload)
      console.log('quiz ref 2' + state.quizReferenceId)
    },
    addToQuizForGender: (state, action) => {
      state.gender = action.payload
    },
    addCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },    
    addToQuizForGoal: (state, action) => {
      state.goal = action.payload
    },
    addToQuizForCurrentMindState: (state, action) => {
      state.currentMindState = action.payload
    },
    addToQuizForMindStateYouWant: (state, action) => {
      state.mindStateYouWant = action.payload
    },
    addToQuizForMeditationPractices: (state, action) => {
      state.meditationPractices = action.payload
    },
    addToQuizForLastFeltPeace: (state, action) => {
      state.lastFeltPeace = action.payload
    },
    addToQuizForMindfulnessPracticeLevel: (state, action) => {
      state.mindfulnessPracticeLevel = action.payload
    },
    addToQuizForSeekMeditationBenefit: (state, action) => {
      state.seekMeditationBenefit = action.payload
    },
    addToQuizForHandleTrafficSituation: (state, action) => {
      state.handleTrafficSituation = action.payload
    },
    addToQuizForImproveMentalWellbeing: (state, action) => {
      state.improveMentalWellbeing = action.payload
    },
    addToQuizForSelfCareTiming: (state, action) => {
      state.selfCareTiming = action.payload
    },
    addToQuizForControlImportantThings: (state, action) => {
      state.controlImportantThings = action.payload
    },
    addToQuizForDifficultyPiling: (state, action) => {
      state.difficultyPiling = action.payload
    },
    addToQuizForIdealStressLevel: (state, action) => {
      state.idealStressLevel = action.payload
    },
    addToQuizForCurrentAge: (state, action) => {
      state.currentAge = action.payload
    },
    addToQuizForDifferentFeels: (state, action) => {
      state.differentFeels = action.payload
    },
    addToQuizForTypicalSleep: (state, action) => {
      state.typicalSleep = action.payload
    },
    addToQuizForFallingAsleep: (state, action) => {
      state.fallingAsleep = action.payload
    },
    addToQuizForScreenTime: (state, action) => {
      state.screenTime = action.payload
    },
    addToQuizForHandleStressSituation: (state, action) => {
      state.handleStressSituation = action.payload
    },
    addToQuizForStressReductionPlan: (state, action) => {
      state.stressReductionPlan = action.payload
    },
    addToQuizForStatementImmediateProblem: (state, action) => {
      state.statementImmediateProblem = action.payload
    },
    addToQuizForStatementMultitask: (state, action) => {
      state.statementMultitask = action.payload
    },
    addToQuizForStatementDistraction: (state, action) => {
      state.statementDistraction = action.payload
    },
    addToQuizForStatementOldHabits: (state, action) => {
      state.statementOldHabits = action.payload
    },
    addToQuizForStatementOverwhelm: (state, action) => {
      state.statementOverwhelm = action.payload
    },
    addToQuizForQuizEmail: (state, action) =>{
      state.quizEmail = action.payload
    },
    addToQuizForPssScoreForQuestionOne: (state, action) =>{
      state.pssScoreOne = action.payload
    },  
    addToQuizForPssScoreForQuestionTwo: (state, action) =>{
      state.pssScoreTwo = action.payload
    }, 
    addToQuizForPssSStrength: (state, action) =>{
      state.pssStrength = action.payload
    },   
  }
})

export const {
  // isShowLogin,
  isShowQuizHeader,
  addToQuizForQuizReferenceId,
  addToQuizForGender,
  addCurrentStep,
  addToQuizForGoal,
  addToQuizForCurrentMindState,
  addToQuizForMindStateYouWant,
  addToQuizForMeditationPractices,
  addToQuizForLastFeltPeace,
  addToQuizForMindfulnessPracticeLevel,
  addToQuizForSeekMeditationBenefit,
  addToQuizForHandleTrafficSituation,
  addToQuizForImproveMentalWellbeing,
  addToQuizForSelfCareTiming,
  addToQuizForControlImportantThings,
  addToQuizForDifficultyPiling,
  addToQuizForIdealStressLevel,
  addToQuizForCurrentAge,
  addToQuizForDifferentFeels,
  addToQuizForTypicalSleep,
  addToQuizForFallingAsleep,
  addToQuizForScreenTime,
  addToQuizForHandleStressSituation,
  addToQuizForStressReductionPlan,
  addToQuizForStatementImmediateProblem,
  addToQuizForStatementMultitask,
  addToQuizForStatementDistraction,
  addToQuizForStatementOldHabits,
  addToQuizForStatementOverwhelm,
  addToQuizForQuizEmail,
  addToQuizForPssScoreForQuestionOne,
  addToQuizForPssScoreForQuestionTwo,
  addToQuizForPssSStrength
} = quizSlice.actions

export default quizSlice.reducer