import React, { useEffect } from 'react'

import styles from './StatementOldHabits.module.css'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { addToQuizForStatementOldHabits, addCurrentStep } from '../../reducers/quiz'

const StatementOldHabits = () => {
    const statementOldHabits = useSelector((state) => state.quiz.statementOldHabits)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(24))
    });

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForStatementOldHabits(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/statement-overwhelm' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.number__rating__wrap}>
            <h1 className={styles.sec__title}>“I usually only manage to practice mindfulness or meditation consistently for a few weeks before returning to my old habits.”</h1>
            <h1 className={styles.sec__sub__title}>Does the statement above describe you?</h1>

            <div className={styles.number__rating__container}>
                <div className={styles.number__rating}>
                    <div className={`${styles.unit__rate} ${statementOldHabits === '1' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '1')}}>1</div>
                    <div className={`${styles.unit__rate} ${statementOldHabits === '2' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '2')}}>2</div>
                    <div className={`${styles.unit__rate} ${statementOldHabits === '3' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '3')}}>3</div>
                    <div className={`${styles.unit__rate} ${statementOldHabits === '4' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '4')}}>4</div>
                    <div className={`${styles.unit__rate} ${statementOldHabits === '5' ? styles.active : ''}`}
                     onClick={e => {onGoalSelected(e, '5')}}>5</div>
                </div>
                <div className={styles.number__rating__label}>
                    <div>Not at all</div>
                    <div>Yes, definitely</div>
                </div>
            </div>
        </div>
    )
}

export default StatementOldHabits