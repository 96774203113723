import React, { useEffect } from 'react'

import { Outlet, useLocation } from 'react-router-dom'

import QuizHeader from '../../components/quiz-header/QuizHeader'
import { useSelector } from 'react-redux'

const Quiz = () => {
  const isShowHeaderState = useSelector((state) => state.quiz.showQuizHeader)
  const location = useLocation();
  useEffect(() => {
    const cleanUp = setTimeout(() => {
      const quizPath = location.pathname.replace("/quiz/", "").replace("/", "");
      window.mixpanel.track(quizPath, {
        'type': 'viewcontent'
      })
      window.mixpanel.track('ViewContent', {
        'type':'viewcontent'
      })
      window.mixpanel.track_pageview();
    }, 100);
    // Clean up
    return () => clearTimeout(cleanUp);
  }, [location]);

  console.log(isShowHeaderState)
  return (
    <div>
      {isShowHeaderState ? <QuizHeader></QuizHeader> : ''}
      <Outlet />
    </div>
  )
}

export default Quiz