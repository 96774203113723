import { configureStore } from '@reduxjs/toolkit'
import quizReducer from './reducers/quiz'

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, 
  // FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER 
} from 'redux-persist';


const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, quizReducer)

export const store = configureStore({
  reducer: {
    quiz: persistedReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  //   serializableCheck: {
  //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //   },
  // }),
})

export const persistor = persistStore(store)