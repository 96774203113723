import React, { useEffect } from 'react'

import styles from './CurrentAge.module.css'
import OneImage from '../../images/one.png'
import { addCurrentStep, addToQuizForCurrentAge } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const CurrentAge = () => {

    const currentAge = useSelector((state) => state.quiz.currentAge)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(14))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])


    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForCurrentAge(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/different-feels' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.currentAge__wrap}>
            <h1 className={styles.sec__title}>What is your age?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${currentAge === 'Below 18' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Below 18')}}>
                    <div className={styles.goal__item__title}>
                        Below 18
                    </div>
                </div>

                <div className={`${styles.goal__item} ${currentAge === '18-24' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, '18-24')}}>
                    <div className={styles.goal__item__title}>
                        18-24
                    </div>
                </div>

                <div className={`${styles.goal__item} ${currentAge === '25-34' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, '25-34')}}>
                    <div className={styles.goal__item__title}>
                        25-34
                    </div>
                </div>

                <div className={`${styles.goal__item} ${currentAge === '35-44' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, '35-44')}}>
                    <div className={styles.goal__item__title}>
                        35-44
                    </div>
                </div>

                <div className={`${styles.goal__item} ${currentAge === '45-54' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, '45-54')}}>
                    <div className={styles.goal__item__title}>
                        45-54
                    </div>
                </div>
                <div className={`${styles.goal__item} ${currentAge === '55-64' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, '55-64')}}>
                    <div className={styles.goal__item__title}>
                        55-64
                    </div>
                </div>
                <div className={`${styles.goal__item} ${currentAge === '65 or above' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, '65 or above')}}>
                    <div className={styles.goal__item__title}>
                    65 or above
                    </div>
                </div>
            </div>



            <div className={styles.bmi__note__wrap}>
                <div className={styles.bmi__note__img}>
                    <img src={OneImage} alt="" />
                </div>
                
                <div className="">
                    <div className={styles.bmi__note__title}>We ask your age to create your personal mindfulness plan</div>
                    <div className={styles.bmi__note__sub__title}>Stressors and the way we handle stress can vary significantly depending on our age. By knowing your age, we can create a more personalized mindfulness plan suited to your specific needs.</div>
                </div>
            </div>
        </div>
    )
}

export default CurrentAge