import React, { useEffect } from 'react'

import styles from './StressScore.module.css'
import PssImage from '../../images/pss-chart.png';
import Header from '../header-home/HeaderHome';
import { isShowQuizHeader } from '../../reducers/quiz'

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const StressScore = () => {
    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const quizReferenceId = useSelector(state => state.quiz.quizReferenceId)

    const dispatch = useDispatch()
    // const navigate = useNavigate()

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    useEffect(()=>{
        dispatch(isShowQuizHeader(false))

        return (() =>{
            dispatch(isShowQuizHeader(true))
        })
    })

    useEffect(() => {
        console.log(quizReferenceId + ' quiz ref id')
    })

    function onContinueClicked(){
        setTimeout(() => {
            dispatch(isShowQuizHeader(true))
            window.location.href = `https://the-comfort-meditation.com/?quiz_reference_id=${quizReferenceId}`
             + (queryParams ? `&${queryParams}` : '')
        }, 500);
    }

    return (
        <div>
            <Header></Header>

            <div className={styles.stress__score__main}>

                <h1 className={styles.sec__sub__title}>Your Perceived Stress Score</h1>

                <div className={styles.graph__container}>
                    <img src={PssImage} alt="PssImage" />
                    <div className={styles.graph__note__text}>This chart is for illustrative purposes only</div>
                </div>

                <h1 className={styles.sec__title}>Your 4-week Plan to Reduce Stress and Improve Mindfulness is Ready!</h1>

            </div>

            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn}
                    onClick={onContinueClicked}>Continue</button>
            </div>
        </div>
    )
}

export default StressScore