import React, { useState, useEffect } from 'react'

import styles from './MeditationPractices.module.css'

import MeditationPractices1 from '../../images/MeditationPractices1.png'
import MeditationPractices2 from '../../images/MeditationPractices2.png'
import MeditationPractices3 from '../../images/MeditationPractices3.png'
import MeditationPractices4 from '../../images/MeditationPractices4.png'
import MeditationPractices5 from '../../images/MeditationPractices5.png'

import MeditationPracticesMale1 from '../../images/MeditationPracticesMale1.png'
import MeditationPracticesMale2 from '../../images/MeditationPracticesMale2.png'
import MeditationPracticesMale3 from '../../images/MeditationPracticesMale3.png'
import MeditationPracticesMale4 from '../../images/MeditationPracticesMale4.png'
import MeditationPracticesMale5 from '../../images/MeditationPracticesMale5.png'

import { addToQuizForMeditationPractices, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const MeditationPractices = () => {
    const [meditationPracticesArr, setMeditationPracticesArr] = useState([])

    const gender = useSelector((state) => state.quiz.gender)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(4))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, targetZone){
        if(meditationPracticesArr.includes(targetZone)){
            let tagerZoneFilteredArr = meditationPracticesArr.filter(n => n !== targetZone)
            setMeditationPracticesArr(tagerZoneFilteredArr)
        } else {
            setMeditationPracticesArr([...meditationPracticesArr, targetZone])
        }
    }

    function onContinueClicked(e){
        dispatch(addToQuizForMeditationPractices(meditationPracticesArr))

        setTimeout(() => {
            navigate('/quiz/last-felt-peace' + queryParams)
        }, 500);
    }



    return (
        <div className={styles.meditation__practices__wrap}>
            <h1 className={styles.sec__title}>Choose the types of meditation practices that best suit you:</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${meditationPracticesArr.includes('Step-by-Step Instructional') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Step-by-Step Instructional')}}>
                    <div className={styles.goal__item__title}>
                        <div className={styles.custom__checkbox}>
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.937 7.649a1.105 1.105 0 0 0-1.605 0 1.213 1.213 0 0 0 0 1.668l3.83 3.981 7.506-7.804a1.213 1.213 0 0 0 0-1.668 1.105 1.105 0 0 0-1.605 0L6.16 9.96 3.937 7.649Z" fill="#49C95F"></path></svg>
                        </div>
                        Step-by-Step Instructional
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? MeditationPractices1 : MeditationPracticesMale1} alt="Step-by-Step Instructional" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${meditationPracticesArr.includes('Short, Daily Practices') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Short, Daily Practices')}}>
                    <div className={styles.goal__item__title}>
                        <div className={styles.custom__checkbox}>
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.937 7.649a1.105 1.105 0 0 0-1.605 0 1.213 1.213 0 0 0 0 1.668l3.83 3.981 7.506-7.804a1.213 1.213 0 0 0 0-1.668 1.105 1.105 0 0 0-1.605 0L6.16 9.96 3.937 7.649Z" fill="#49C95F"></path></svg>
                        </div>
                        Short, Daily Practices
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? MeditationPractices2 : MeditationPracticesMale2} alt="Short, Daily Practices" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${meditationPracticesArr.includes('Meditation with Music or Sounds') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Meditation with Music or Sounds')}}>
                    <div className={styles.goal__item__title}>
                        <div className={styles.custom__checkbox}>
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.937 7.649a1.105 1.105 0 0 0-1.605 0 1.213 1.213 0 0 0 0 1.668l3.83 3.981 7.506-7.804a1.213 1.213 0 0 0 0-1.668 1.105 1.105 0 0 0-1.605 0L6.16 9.96 3.937 7.649Z" fill="#49C95F"></path></svg>
                        </div>
                        Meditation with Music or Sounds
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? MeditationPractices3 : MeditationPracticesMale3} alt="Meditation with Music or Sounds" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${meditationPracticesArr.includes('Visual Guided Meditations') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Visual Guided Meditations')}}>
                    <div className={styles.goal__item__title}>
                        <div className={styles.custom__checkbox}>
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.937 7.649a1.105 1.105 0 0 0-1.605 0 1.213 1.213 0 0 0 0 1.668l3.83 3.981 7.506-7.804a1.213 1.213 0 0 0 0-1.668 1.105 1.105 0 0 0-1.605 0L6.16 9.96 3.937 7.649Z" fill="#49C95F"></path></svg>
                        </div>
                        Visual Guided Meditations
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? MeditationPractices4 : MeditationPracticesMale4} alt="Visual Guided Meditations" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${meditationPracticesArr.includes('Mindfulness Challenges') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Mindfulness Challenges')}}>
                    <div className={styles.goal__item__title}>
                        <div className={styles.custom__checkbox}>
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.937 7.649a1.105 1.105 0 0 0-1.605 0 1.213 1.213 0 0 0 0 1.668l3.83 3.981 7.506-7.804a1.213 1.213 0 0 0 0-1.668 1.105 1.105 0 0 0-1.605 0L6.16 9.96 3.937 7.649Z" fill="#49C95F"></path></svg>
                        </div>
                        Mindfulness Challenges
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? MeditationPractices5 : MeditationPracticesMale5} alt="Mindfulness Challenges" />
                    </div>
                </div>
                

                <div className={styles.shadow__btn}>
                    <button className={styles.gender__select__btn} disabled={meditationPracticesArr.length < 1}
                     onClick={e => {onContinueClicked(e)}}>Continue</button>
                </div>
            </div>
        </div>
    )
}

export default MeditationPractices