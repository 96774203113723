import React, {useEffect} from 'react'

import styles from './DifferentFeels.module.css'

import MorningImage from '../../images/morning.png'
import NoonImage from '../../images/noon.png'
import MoonImage from '../../images/moon.png'
import MeditationImage from '../../images/meditating.png'

import { addToQuizForDifferentFeels, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const DifferentFeels = () => {
    const differentFeels = useSelector((state) => state.quiz.differentFeels)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(15))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForDifferentFeels(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/typical-sleep' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.diffrentFeels__wrap}>
            <h1 className={styles.sec__title}>How do you feel at different times of the day?</h1>
            <h2 className={styles.sec__sub__title}>Select the most appropriate options.</h2>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${differentFeels === `I feel stressed when I wake up` ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, `I feel stressed when I wake up`)}}>
                    <div className={styles.goal__item__title}>
                        I feel stressed when I wake up
                    </div>
                    <div className={styles.activity__img}>
                        <img src={MorningImage} alt="MorningImage" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${differentFeels === 'I get anxious in the afternoons' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'I get anxious in the afternoons')}}>
                    <div className={styles.goal__item__title}>
                        I get anxious in the afternoons
                    </div>
                    <div className={styles.activity__img}>
                        <img src={NoonImage} alt="NoonImage" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${differentFeels === 'I have trouble relaxing before bed' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'I have trouble relaxing before bed')}}>
                    <div className={styles.goal__item__title}>
                        I have trouble relaxing before bed
                    </div>
                    <div className={styles.activity__img}>
                        <img src={MoonImage} alt="MoonImage" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${differentFeels === 'I generally feel calm and centered throughout the day' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'I generally feel calm and centered throughout the day')}}>
                    <div className={styles.goal__item__title}>
                        I generally feel calm and centered throughout the day
                    </div>
                    <div className={styles.activity__img}>
                        <img src={MeditationImage} alt="MeditationImage" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DifferentFeels