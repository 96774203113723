import React from 'react'

import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import './QuizHeader.css'
import Logo from '../../images/logo.png'

const QuizHeader = () => {

    const currentStep = useSelector((state) => state.quiz.currentStep)

    let navigate = useNavigate()

    function onQuizBack(){
        navigate(-1)
    }

  return (
    <div>
        <div className="steps__header__wrap">
            <div className="steps__header">
                <button className="back__btn" onClick={onQuizBack}>
                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.355 5.284a.75.75 0 01-.002 1.042l-4.077 4.207H21.25a.75.75 0 01.75.75v1.447a.75.75 0 01-.75.75H7.277l4.076 4.207a.75.75 0 01.002 1.042l-1.027 1.066a.75.75 0 01-1.08.001l-7.03-7.268a.75.75 0 010-1.043l7.03-7.269a.75.75 0 011.08.001l1.027 1.067z" fill="#D4DAE0"></path>
                    </svg>
                </button>
                <div className="steps__header--logo">
                    <Link to="/"><img src={Logo} alt={Logo} /></Link>
                </div>
                <div className="steps__count__wrap">
                    <span className="steps__count">{currentStep}</span>
                    <span className="steps__count__separator">/</span>
                    <span className="steps__count__total">26</span>
                </div>
            </div>

            <div className="steps__progress">
                <div className="steps__progress--fill" style={{width : (100/26) * currentStep + '%'}}></div>
            </div>
        </div>
    </div>
  )
}

export default QuizHeader