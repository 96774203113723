import React, { useEffect, useState } from 'react'

import styles from './QuizReviews.module.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import TrustPilotLogo from '../../images/Trustpilot_Logo.png'
import TrustPilotStars from '../../images/tp-stars.png'
import Review1 from '../../images/charly.png'

import Header from '../header-home/HeaderHome'
import { useDispatch } from 'react-redux'
import { isShowQuizHeader } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'

const QuizReviews = () => {

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Loader State
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(true)
    const dashArray = 2 * Math.PI * 71
    const dashOffset = dashArray * ((100 - progress) / 100)

    useEffect(()=>{
        dispatch(isShowQuizHeader(false))

        return () => {
            dispatch(isShowQuizHeader(true))
        }
    })

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    useEffect(() => {

        let loadingTimeout = setTimeout(() => {
            if (loading >= 100) {
                return
            }
            setProgress(progress + 1)
            console.log(progress)
        }, 150)
    
        if (progress === 100) {
            setLoading(false)
            clearTimeout(loadingTimeout)

            setTimeout(() => {
                navigate('/quiz/quiz-email' + queryParams)
            }, 500);
        }
    
        return () => {
          clearTimeout(loadingTimeout)
        }
    }, [progress, loading, navigate, queryParams])

    let settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 1500
    };

    return (
        <div>
            <Header></Header>

            <div className={styles.quiz__reviews__main}>
                <svg width="176" height="176" className={styles.round__loader}>
                    <circle stroke="#f4f6f3" cx="88" cy="88" r="81" strokeWidth="14"></circle>
                    <circle stroke="#49c95f" cx="88" cy="88" r="71" strokeWidth="14" strokeDasharray="446.10615680975064"
                     strokeDashoffset={dashOffset} className={styles.round__loader__stroke}
                      strokeLinecap="round"
                    >
                    </circle>
                    <text x="88" y="88" alignmentBaseline="middle" textAnchor="middle"
                     className={styles.loader__text} style={{fill: '#454e57'}}>
                        {progress}%
                    </text>
                </svg>

                <div className={styles.plan__note}>
                    Creating your personal plan…
                </div>

                <div>
                    <h1 className={styles.sec__title}>250 thousand</h1>
                    <h2 className={styles.sec__sub__title}>users have chosen us</h2>
                </div>

                <div className={`${styles.slider__container} quiz__reivews__slider__container`}>
                    <Slider {...settings}>
                        <div className={styles.slide__item}>
                            <div className={styles.review__head}>
                                <div>
                                    <div className={styles.review__name__wrap}>
                                        <div className={styles.review__initials}>
                                            JE
                                        </div>
                                        <div className={styles.review__name}>
                                            John
                                        </div>
                                    </div>
                                    <div className={styles.trustpilot__stars}>
                                        <img src={TrustPilotStars} alt="TrustPilotStars" />
                                    </div>
                                </div>
                                <div className={styles.truspilot__logo__img}>
                                    <img src={TrustPilotLogo} alt="TrustPilotLogo" />
                                </div>
                            </div>
                            <div className={styles.review__text}>
                            "Fantastic app. For me, this app has been a game-changer. The guided meditations and the gentle reminders for mindful breaks have made a real difference in my life."
                            </div>
                        </div>
                        <div className={styles.slide__item}>
                            <div className={styles.review__head}>
                                <div>
                                    <div className={styles.review__name__wrap}>
                                        <div className={styles.review__initials}>
                                            <img src={Review1} alt="Review1" />
                                        </div>
                                        <div className={styles.review__name}>
                                            Sarah
                                        </div>
                                    </div>
                                    <div className={styles.trustpilot__stars}>
                                        <img src={TrustPilotStars} alt="TrustPilotStars" />
                                    </div>
                                </div>
                                <div className={styles.truspilot__logo__img}>
                                    <img src={TrustPilotLogo} alt="TrustPilotLogo" />
                                </div>
                            </div>
                            <div className={styles.review__text}>
                            "I couldn't be happier with this app. In just a couple of weeks, I've noticed a significant reduction in my stress levels and I'm sleeping much better. The guided meditations are easy to follow and I always feel refreshed afterward."    
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default QuizReviews