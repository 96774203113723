import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isShowQuizHeader } from '../../reducers/quiz'

import styles from './MidTwentyThree.module.css'
import HealthyImage from '../../images/stress-management.png'
import { useNavigate, useSearchParams } from 'react-router-dom'

const MidTwentyThree = () => {
    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(isShowQuizHeader(false))

        return () => {
            dispatch(dispatch(isShowQuizHeader(true)))
        }
    })

    function onContinueClicked(e){
        setTimeout(() => {
            navigate('/quiz/statement-old-habits' + queryParams)
        }, 500);
    }
    

    return (
        <div className={`${styles.midTwentyThree__wrap} is__full-height`}>
            <div className={styles.midTwentyThree__main}>
                <div className={styles.banner__image}>
                    <img src={HealthyImage} alt="Healthy" />
                </div>
                <h1 className={styles.title}>One step at a time.</h1>
                <h2 className={styles.text}>
                    "Creating new habits often isn't easy, 
                    especially when it comes to building 
                    mindfulness and stress reduction 
                    routines. This transformation 
                    happens gradually, and we're here to 
                    support you every step of the way."    
                </h2>
            </div>
            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn}
                 onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default MidTwentyThree