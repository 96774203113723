import React from 'react'
import styles from './RechartLineGraph.module.css'
import {
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    // Legend,
    Area,
    // Label,
    // LabelList,
    ComposedChart,
} from 'recharts';

const data = [
    {
        name: 'Week 1',
        pv: 100,
        amt: 80,
    },
    {
        name: 'week 2',
        pv: 100,
        amt: 55,
    },
    {
        name: 'week 3',
        pv: 100,
        amt: 45,
    },
    {
        name: 'Week 4',
        pv: 100,
        amt: 15,
    },
];

const CustomizedDot = (props) => {
    const { cx, cy, 
        // stroke, 
        // payload, 
        value } = props;
  

    if (value > 60) {
        return (
            <svg x={cx - 45}
                y={cy - 50} xmlns="http://www.w3.org/2000/svg" width="94.003" height="60.999" viewBox="0 0 94.003 60.999">
                <g id="Group_146" data-name="Group 146" transform="translate(-12.999 -468.001)">
                <g id="Group_145" data-name="Group 145">
                    <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(49 507)" fill="#fff" stroke="#f08a85" strokeWidth="2.5">
                    <circle cx="11" cy="11" r="11" stroke="none"/>
                    <circle cx="11" cy="11" r="9.75" fill="none"/>
                    </g>

                    <path className='transOpacity' id="Union_1" data-name="Union 1" d="M-12939.516-174.16l-2.841-2.841H-12980a4,4,0,0,1-4-4v-20a4,4,0,0,1,4-4h86a4,4,0,0,1,4,4v20a4,4,0,0,1-4,4h-38.432l-2.841,2.841a2.989,2.989,0,0,1-2.12.877A3,3,0,0,1-12939.516-174.16Z" transform="translate(12997 673)" fill="#292e33"/>
                    <text className='transOpacity' id="Stress_Level" data-name="Stress Level" transform="translate(60 487)" fill="#fff" fontSize="14" fontFamily="Roboto-Bold, Roboto" fontWeight="700"><tspan x="-38.654" y="0">Stress Level</tspan></text>
                </g>
                </g>
            </svg>
        );
    }

    if (value < 30) {
        return ( 
            
            <svg x={cx - 45}
                y={cy - 50} xmlns="http://www.w3.org/2000/svg" width="94.003" height="60.999" viewBox="0 0 94.003 60.999">
                <g id="Group_146" data-name="Group 146" transform="translate(-12.999 -468.001)">
                <g id="Group_145" data-name="Group 145">
                    <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(49 507)" fill="#fff" stroke="#69c25f" strokeWidth="2.5">
                    <circle cx="11" cy="11" r="11" stroke="none"/>
                    <circle cx="11" cy="11" r="9.75" fill="none"/>
                    </g>
                    <path className='transOpacity' id="Union_1" data-name="Union 1" d="M-12939.516-174.16l-2.841-2.841H-12980a4,4,0,0,1-4-4v-20a4,4,0,0,1,4-4h86a4,4,0,0,1,4,4v20a4,4,0,0,1-4,4h-38.432l-2.841,2.841a2.989,2.989,0,0,1-2.12.877A3,3,0,0,1-12939.516-174.16Z" transform="translate(12997 673)" fill="#292e33"/>
                    <text className='transOpacity' id="Stress_Level" data-name="Stress Level" transform="translate(60 487)" fill="#fff" fontSize="14" fontFamily="Roboto-Bold, Roboto" fontWeight="700"><tspan x="-38.654" y="0">Stress Level</tspan></text>
                </g>
                </g>
            </svg>
        );
    }
};

const CTool = () => {
    return (
        <div className={styles.custom__tooltip}>
            Stress Level
        </div>
    )
}



const RechartLineGraph = () => {

    return (

        <ComposedChart width={360} height={245} data={data} margin={{top: 35, right: 50, left: -15, bottom: 5}} className='stress-reduction-composed-chart'>
        <defs>
          <linearGradient id="colorUv" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="5%" stopColor="#5a5669" stopOpacity={0.1}/>
            <stop offset="95%" stopColor="#eb796a" stopOpacity={0.1}/>
          </linearGradient>
        </defs>

        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%"   stopColor="#f28887"/>
                <stop offset="50%" stopColor="#e2ca60"/>
                <stop offset="100%" stopColor="#50ca5e"/>
            </linearGradient>
        </defs>

        <XAxis dataKey="name" ></XAxis>
        <YAxis dataKey="pv" tick={false}/>


            <Tooltip content={<CTool></CTool>} active={true} offset={15} 
            itemStyle={{backgroundColor: '#000', color: '#fff', borderRadius: '8px'}} />

        
        

        <CartesianGrid horizontal={false} stroke="#DDD" strokeDasharray="3 3"/>
        
        <Line type="monotone" strokeLinecap="round" strokeWidth={10}
            dataKey="amt"
            animationDuration={2500}
            stroke="url(#gradient)"
            dot={<CustomizedDot/>}
            activeDot={false}
        >
            
        </Line>

        <Area type="monotone" dataKey="amt" strokeWidth={2} fillOpacity={1} animationDuration={2500} fill="url(#colorUv)" stroke='none'>

        </Area>
      </ComposedChart>
    )
}

export default RechartLineGraph