import React, { useEffect, useState } from 'react'

import styles from './MindfulnessSummary.module.css'

import ThinkImage from '../../images/think.png'
import StretchImage from '../../images/stretching.svg'
import MediatationImage from '../../images/meditation.svg'
// import SignalImage from '../../images/signal.svg'
import BeginnerLevelImage from '../../images/beginner-level.jpg'
import MedImage from '../../images/med-sum.png'
import MedMaleImage from '../../images/med-male-sum.png'


import Header from '../header-home/HeaderHome'
import { useDispatch, useSelector } from 'react-redux'
import { addToQuizForPssSStrength, isShowQuizHeader } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'

const MindfulnessSummary = () => {
    const goal = useSelector((state) => state.quiz.goal)
    const meditationPractices = useSelector((state) => state.quiz.meditationPractices)
    const gender = useSelector((state) => state.quiz.gender)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(isShowQuizHeader(false))

        return () => {
            dispatch(isShowQuizHeader(true))
        }
    })

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])


    const pssScoreOne = useSelector((state) => state.quiz.pssScoreOne)
    const pssScoreTwo = useSelector((state) => state.quiz.pssScoreTwo)

    const [pssStrength, setPssStrength] = useState('')
    const [pssPercentage, setPssPercentage] = useState(Number)
    const [receivedPssScore, setReceivedPssScore] = useState(Number)

    useEffect(()=>{
        setReceivedPssScore(+pssScoreOne + +pssScoreTwo)
        if (receivedPssScore <= 13){
            setPssStrength('Moderate')
            setPssPercentage(10)
            dispatch(addToQuizForPssSStrength(pssStrength))
        } else if (receivedPssScore > 14 && receivedPssScore <= 26){
            setPssStrength('High')
            setPssPercentage(47)
            dispatch(addToQuizForPssSStrength(pssStrength))
        } else if (receivedPssScore > 27 && receivedPssScore <= 40){
            setPssStrength('Extreme')
            setPssPercentage(83)
            dispatch(addToQuizForPssSStrength(pssStrength))
        }

        console.log(pssPercentage)
        console.log(meditationPractices)
    }, [pssScoreOne, pssScoreTwo, pssPercentage, receivedPssScore, pssStrength, meditationPractices, dispatch])

    function onContinueClicked(e){
        setTimeout(() => {
            navigate('/quiz/quiz-reviews' + queryParams)
        }, 500);
    }


    return (
        <div>
            <Header></Header>
            <div className={styles.mindfulness__main}>
                <h1 className={styles.sec__title}>Summary of Your Mindfulness Profile</h1>

                <div className={styles.mindfulness__graph__container}>
                    <div className={styles.mindfulness__graph__header}>
                        <p className={styles.mindfulness__graph__header__title}>Perceived Stress Score</p>
                        <p className={styles.mindfulness__graph__header__def}>Healthy Range - Below 14</p>
                    </div>
                    <div className={styles.svg__container}>
                        <svg width="343" height="76" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 66h343M0 50h343M0 34h343M0 17h343M221.172 75.5V.5M170.977 75.5V.5M271.367 75.5V.5M321.562 75.5V.5M121.828 75.5v-74M71.633 75.5v-74M21.437 75.5v-74" stroke="#E9ECEF" strokeDasharray="3 2"></path>
                        </svg>
                        <div className={styles.svg__line__wrap}>
                            <svg width="301" height="9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="301" height="8" rx="4" fill="url(#graph_svg__paint0_linear_1894_3495)"></rect>
                                <defs>
                                    <linearGradient id="graph_svg__paint0_linear_1894_3495" x1="0" y1="4.5" x2="301" y2="4.5" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.026" stopColor="#93D054"></stop>
                                        <stop offset="0.526" stopColor="#F7D147"></stop>
                                        <stop offset="1" stopColor="#EA0A0A"></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div className={styles.svg__line__handle} style={{left: `${pssPercentage}%`}}>
                                <div className={styles.svg__line__handle__tooltip}>
                                    <p className={styles.svg__line__handle__tooltip__caption}>You - {receivedPssScore}</p>
                                </div>
                                <div className={styles.svg__line__handle__round}></div>
                            </div>
                        </div>
                        <div className={styles.svg__graph__footer__label__wrap}>
                            <p className={`${styles.svg__graph__footer__label}
                             ${pssStrength === 'Moderate' ? styles.active : ''}`}>MODERATE</p>
                            <p className={`${styles.svg__graph__footer__label}
                             ${pssStrength === 'High' ? styles.active : ''}`}>HIGH</p>
                            <p className={`${styles.svg__graph__footer__label}
                             ${pssStrength === 'Extreme' ? styles.active : ''}`}>EXTREME</p>
                        </div>
                    </div>
                </div>

                <div className={styles.med__sum__list}>
                <div className={styles.mindfulness__summary__lists}>
                    <div className={styles.mindfulness__summary__item}>
                        <div className={styles.mindfulness__summary__item__img}>
                            <img src={StretchImage} alt="Streching" />
                        </div>
                        <div>
                            <div className={styles.mindfulness__summary__item__head}>Goal Orientation</div>
                            <div className={styles.mindfulness__summary__item__title}>{goal}</div>
                        </div>
                    </div>
                    <div className={styles.mindfulness__summary__item}>
                        <div className={styles.mindfulness__summary__item__img}>
                            <img src={MediatationImage} alt="Meditation" />
                        </div>
                        <div>
                            <div className={styles.mindfulness__summary__item__head}>Meditation Focus</div>
                            <div className={styles.mindfulness__summary__item__title}>{meditationPractices[0]}</div>
                        </div>
                    </div>
                    <div className={styles.mindfulness__summary__item}>
                        <div className={`${styles.mindfulness__summary__item__img} ${styles.beginner_level__img}`}>
                            <img src={BeginnerLevelImage} alt="Signal" />
                        </div>
                        <div>
                            <div className={styles.mindfulness__summary__item__head}>Mindfulness Level</div>
                            <div className={styles.mindfulness__summary__item__title}>Beginner</div>
                        </div>
                    </div>
                </div>
                <div className={styles.med__sum}>
                    <img src={gender === 'female' ? MedImage : MedMaleImage} alt="MedImage" />
                </div>
                </div>

                {
                    pssStrength === 'Moderate' 
                    ?
                    <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={ThinkImage} alt="Think" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Potential Risks of Moderate Stress</div>
                        <div className={styles.bmi__note__sub__title}>Regular sleep issues, elevated blood pressure, frequent feelings of being overwhelmed, potential for burnout</div>
                    </div>
                    </div>
                    :
                    ''
                }

                {
                    pssStrength === 'High' 
                    ?
                    <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={ThinkImage} alt="Think" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Potential Risks of High Stress</div>
                        <div className={styles.bmi__note__sub__title}>Chronic sleep issues, high blood pressure, anxiety, signs of depression, frequent burnout</div>
                    </div>
                    </div>
                    :
                    ''
                }

                {
                    pssStrength === 'Extreme' 
                    ?
                    <div className={`${styles.bmi__note__wrap} ${styles.warn}`}>
                    <div className={styles.bmi__note__img}>
                        <img src={ThinkImage} alt="Think" />
                    </div>
                    
                    <div className="">
                        <div className={styles.bmi__note__title}>Potential Risks of Extreme Stress</div>
                        <div className={styles.bmi__note__sub__title}> Severe sleep issues, risk for heart disease, chronic anxiety and/or depression, severe productivity and relationship issues</div>
                    </div>
                    </div>
                    :
                    ''
                }
                
            </div>
            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn}
                    onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default MindfulnessSummary