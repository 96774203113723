import React, { useEffect } from 'react'

import styles from './FallingAsleep.module.css'

import { addToQuizForFallingAsleep, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const FallingAsleep = () => {

    const fallingAsleep = useSelector((state) => state.quiz.fallingAsleep)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(17))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForFallingAsleep(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/screen-time' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.handle__stressful__situation__wrap}>
            <h1 className={styles.sec__title}>How long does it typically take you to fall asleep?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${fallingAsleep === `I fall asleep as soon as my head hits the pillow` ? styles.active : ''}`} 
                onClick={ e => {onGoalSelected(e, `I fall asleep as soon as my head hits the pillow`)}}>
                    <div className={styles.goal__item__title}>
                        I fall asleep as soon as my head hits the pillow
                    </div>
                </div>
                <div className={`${styles.goal__item} ${fallingAsleep === 'It takes me about 30 minutes to fall asleep' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'It takes me about 30 minutes to fall asleep')}}>
                    <div className={styles.goal__item__title}>
                        It takes me about 30 minutes to fall asleep
                    </div>
                </div>
                <div className={`${styles.goal__item} ${fallingAsleep === 'I toss and turn for over an hour before falling asleep' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'I toss and turn for over an hour before falling asleep')}}>
                    <div className={styles.goal__item__title}>
                        I toss and turn for over an hour before falling asleep
                    </div>
                </div>
                <div className={`${styles.goal__item} ${fallingAsleep === `I struggle with insomnia and often can't fall asleep for several hours` ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, `I struggle with insomnia and often can't fall asleep for several hours`)}}>
                    <div className={styles.goal__item__title}>
                        I struggle with insomnia and often can't fall asleep for several hours
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FallingAsleep