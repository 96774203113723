import React, {useEffect} from 'react'

import styles from './HandleStressSituation.module.css'

import WorkImage from '../../images/work.png'
import CloudsImage from '../../images/clouds.png'
import HouseImage from '../../images/house.png'
import FamilyImage from '../../images/family.png'
import ConfusedImage from '../../images/confused.png'
import StethoscopeImage from '../../images/stethoscope.png'
import BullsEyeImage from '../../images/bullseye.png'
import SleepImage from '../../images/sleep.png'
import ThinkImage from '../../images/think.png'
import NoImage from '../../images/no.png'

import { addToQuizForHandleStressSituation, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const HandleStressSituation = () => {

    const handleStressSituation = useSelector((state) => state.quiz.handleStressSituation)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""
    
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(19))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForHandleStressSituation(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/stress-reduction-plan' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.handleStressSituation__wrap}>
            <h1 className={styles.sec__title}>Is there a particular situation you wish to handle better through improved mindfulness or stress reduction?</h1>
            <h1 className={styles.sec__sub__title}>Understanding your key areas of concern can help us tailor your mindfulness journey.</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${handleStressSituation === 'High-pressure work or school situations' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'High-pressure work or school situations')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={WorkImage} alt="High-pressure work or school situations" />
                        </div>
                        <div className={styles.medium__text}>
                            High-pressure work or school situations
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'Difficult conversations or confrontations' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'Difficult conversations or confrontations')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={CloudsImage} alt="Difficult conversations or confrontations" />
                        </div>
                        <div className={styles.medium__text}>
                            Difficult conversations or confrontations
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'Times of change or uncertainty (moving, new job, etc.)' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'Times of change or uncertainty (moving, new job, etc.)')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={HouseImage} alt="Times of change or uncertainty (moving, new job, etc.)" />
                        </div>
                        <div className={styles.medium__text}>
                            Times of change or uncertainty (moving, new job, etc.)
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'Balancing work and family life' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'Balancing work and family life')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={FamilyImage} alt="Balancing work and family life" />
                        </div>
                        <div className={styles.medium__text}>
                            Balancing work and family life
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'Managing negative emotions or thoughts' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'Managing negative emotions or thoughts')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={ConfusedImage} alt="Managing negative emotions or thoughts" />
                        </div>
                        <div className={styles.medium__text}>
                            Managing negative emotions or thoughts
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'Dealing with health issues or physical discomfort' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'Dealing with health issues or physical discomfort')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={StethoscopeImage} alt="Dealing with health issues or physical discomfort" />
                        </div>
                        <div className={styles.medium__text}>
                            Dealing with health issues or physical discomfort
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'Improving focus and productivity' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'Improving focus and productivity')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={BullsEyeImage} alt="Improving focus and productivity" />
                        </div>
                        <div className={styles.medium__text}>
                            Improving focus and productivity
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'Sleep problems' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'Sleep problems')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={SleepImage} alt="Sleep problems" />
                        </div>
                        <div className={styles.medium__text}>
                            Sleep problems
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'Other' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'Other')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={ThinkImage} alt="Other" />
                        </div>
                        <div className={styles.medium__text}>
                            Other
                        </div>
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleStressSituation === 'No specific situation' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'No specific situation')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.activity__img}>
                            <img src={NoImage} alt="No specific situation" />
                        </div>
                        <div className={styles.medium__text}>
                            No specific situation
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HandleStressSituation