import React, { useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import HeaderHome from '../../components/header-home/HeaderHome'

import styles from './Homepage.module.css'
import GenderMaleImage from '../../images/gender-male.png'
import GenderFemaleImage from '../../images/gender-female.png'

import { addToQuizForGender } from '../../reducers/quiz'
import { useDispatch } from 'react-redux'


const Homepage = () => {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // useEffect(()=> {
    //     dispatch(isShowLogin(true))

    //     return () => {
    //         dispatch(isShowLogin(false))
    //     }
    // })

    function onGenderSelected(event, gender){
        dispatch(addToQuizForGender(gender))
        navigate('/social-proof' + queryParams)
    }
      useEffect(() => {
    const cleanUp = setTimeout(() => {
      window.mixpanel.track("CM First Step", {
        'type': 'viewcontent'
      })
      window.mixpanel.track('ViewContent', {
        'type':'viewcontent'
      })
    }, 100);
    // Clean up
    return () => clearTimeout(cleanUp);
  }, []);

    return (
        <div>
            <HeaderHome></HeaderHome>

            <section className={`is__full-height ${styles.homepage}`}>
                <h1 className={styles.sec__title}>Let's Create Your Body Profile</h1>
                <h1 className={styles.sec__sub__title}>Select your gender:</h1>

                <div className={styles.gender__action__wrap}>
                    <div className={styles.gender__item}>
                        <img className={styles.gender__img} src={GenderMaleImage} alt="Gender Male" />
                        <button className={`${styles.gender__select__btn} ${styles.male__btn}`} 
                        onClick={ event => {onGenderSelected(event, 'male')}}>Male</button>
                    </div>
                    <div className={styles.gender__item}>
                        <img className={styles.gender__img} src={GenderFemaleImage} alt="Gender Female" />
                        <button className={`${styles.gender__select__btn} ${styles.female__btn}`} 
                        onClick={ event => {onGenderSelected(event, 'female')}}>Female</button>
                    </div>
                </div>

                <div className={styles.gender__footer}>
                    By pressing the “Male” or “Female” button, you confirm and guarantee that you have read, understood, and agreed to our 
                    <Link className="" target="_blank" rel="noopener noreferrer"> Terms of Use</Link>, 
                    <Link className="" target="_blank" rel="noopener noreferrer"> Privacy Policy</Link>, and 
                    <Link className="" target="_blank" rel="noopener noreferrer"> Refund Policy</Link>.
                </div>
            </section>
        </div>
    )
}

export default Homepage