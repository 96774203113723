import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useEffect } from 'react';
import ReactGA from 'react-ga';


import './App.css';

import Quiz from './pages/quiz/Quiz';
import Homepage from "./pages/homepage/Homepage";
import SocialProof from "./pages/social-proof/SocialProof";
import MotivateGoal from "./components/motivate-goal/MotivateGoal";
import CurrentMindState from "./components/current-mind-state/CurrentMindState";
import MindStateYouWant from "./components/mind-state-you-want/MindStateYouWant";
import MeditationPractices from "./components/meditation-practices/MeditationPractices";
import LastFeltPeace from "./components/last-felt-peace/LastFeltPeace";
import MindfulnessPracticeLevel from "./components/mindfulness-practice-level/MindfulnessPracticeLevel";
import SeekMeditationBenefit from "./components/seek-meditation-benefit/SeekMeditationBenefit";
import HandleTrafficSituation from "./components/handle-traffic-situation/HandleTrafficSituation";
import ImproveMentalWellbeing from "./components/improve-mental-wellbeing/ImproveMentalWellbeing";
import SelfCareTiming from "./components/self-care-timing/SelfCareTiming";
import ControlImportantThings from "./components/control-important-things/ControlImportantThings";
import DifficultyPiling from "./components/difficulty-piling/DifficultyPiling";
import IdealStressLevel from "./components/ideal-stress-level/IdealStressLevel";
import CurrentAge from "./components/current-age/CurrentAge";
import DiffrentFeels from "./components/different-feels/DifferentFeels";
import TypicalSleep from "./components/typical-sleep/TypicalSleep";
import FallingAsleep from "./components/falling-asleep/FallingAsleep";
import ScreenTime from "./components/screen-time/ScreenTime";
import HandleStressSituation from "./components/handle-stress-situation/HandleStressSituation";
import StressReductionPlan from "./components/stress-reduction-plan/StressReductionPlan";
import StatementImmediateProblem from "./components/statement-immediate-problem/StatementImmediateProblem";
import MidTwentyOne from "./components/mid-twenty-one/MidTwentyOne";
import StatementMultitask from "./components/statement-multitask/StatementMultitask";
import StatementDistraction from "./components/statement-distraction/StatementDistraction";
import MidTwentyThree from "./components/mid-twenty-three/MidTwentyThree";
import StatementOldHabits from "./components/statement-old-habits/StatementOldHabits";
import StatementOverwhelm from "./components/statement-overwhelm/StatementOverwhelm";
import MidTwentyFive from "./components/mid-twenty-five/MidTwentyFive";
import MindfulnessSummary from "./components/mindfulness-summary/MindfulnessSummary";
import QuizReviews from "./components/quiz-reviews/QuizReviews";
import QuizEmail from "./components/quiz-email/QuizEmail";
import MindfulnessUpdates from "./components/mindfulness-updates/MindfulnessUpdates";
import StressScore from "./components/stress-score/StressScore";





function App() {
  useEffect(() => {
    document.title = 'Med App Quiz';
    
  }, []);


  useEffect(()=>{
    ReactGA.initialize('UA-254347023-62', {debug: true})
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route index exact path="/" element={<Homepage />} />
            <Route path="social-proof" element={<SocialProof />} />
            <Route path="quiz" element={<Quiz />}>
              <Route path="motivate-goal" element={<MotivateGoal />} />
              <Route path="current-mind-state" element={<CurrentMindState />} />
              <Route path="mind-state-you-want" element={<MindStateYouWant />} />
              <Route path="meditation-practices" element={<MeditationPractices />} />
              <Route path="last-felt-peace" element={<LastFeltPeace />} />
              <Route path="mindfulness-practice-level" element={<MindfulnessPracticeLevel />} />
              <Route path="seek-meditation-benefit" element={<SeekMeditationBenefit />} />
              <Route path="handle-traffic-situation" element={<HandleTrafficSituation />} />
              <Route path="improve-mental-wellbeing" element={<ImproveMentalWellbeing />} />
              <Route path="self-care-timing" element={<SelfCareTiming />} />
              <Route path="control-important-things" element={<ControlImportantThings />} />
              <Route path="difficulty-piling" element={<DifficultyPiling />} />
              <Route path="ideal-stress-level" element={<IdealStressLevel />} />
              <Route path="current-age" element={<CurrentAge />} />
              <Route path="different-feels" element={<DiffrentFeels />} />
              <Route path="typical-sleep" element={<TypicalSleep />} />
              <Route path="falling-asleep" element={<FallingAsleep />} />
              <Route path="screen-time" element={<ScreenTime />} />
              <Route path="handle-stress-situation" element={<HandleStressSituation />} />
              <Route path="stress-reduction-plan" element={<StressReductionPlan />} />
              <Route path="statement-immediate-problem" element={<StatementImmediateProblem />} />
              <Route path="mid-twenty-one" element={<MidTwentyOne />} />
              <Route path="statement-multitask" element={<StatementMultitask />} />
              <Route path="statement-distraction" element={<StatementDistraction />} />
              <Route path="mid-twenty-three" element={<MidTwentyThree />} />
              <Route path="statement-old-habits" element={<StatementOldHabits />} />
              <Route path="statement-overwhelm" element={<StatementOverwhelm />} />
              <Route path="mid-twenty-five" element={<MidTwentyFive />} />
              <Route path="mindfulness-summary" element={<MindfulnessSummary />} />
              <Route path="quiz-reviews" element={<QuizReviews />} />
              <Route path="quiz-email" element={<QuizEmail />} />
              <Route path="mindfulness-updates" element={<MindfulnessUpdates />} />
              <Route path="stress-score" element={<StressScore />} />
            </Route>
            <Route path="*" element={<Homepage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
