import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isShowQuizHeader } from '../../reducers/quiz'

import styles from './MidTwentyFive.module.css'
import HealthyImage from '../../images/meditation.png'
import { useNavigate, useSearchParams } from 'react-router-dom'

const MidTwentyFive = () => {
    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(isShowQuizHeader(false))

        return () => {
            dispatch(isShowQuizHeader(true))
        }
    })

    function onContinueClicked(e){
        setTimeout(() => {
            navigate('/quiz/mindfulness-summary' + queryParams)
        }, 500);
    }
    

    return (
        <div className={`${styles.midTwentyFive__wrap} is__full-height`}>
            <div className={styles.midTwentyFive__main}>
                <div className={styles.banner__image}>
                    <img src={HealthyImage} alt="Healthy" />
                </div>
                <h1 className={styles.title}>Awareness is the first step!</h1>
                <h2 className={styles.text}>
                    "Understanding your patterns and recognizing the signs of overwhelm early can help prevent burnout. We're here to help you cultivate this awareness and build resilience."    
                </h2>
            </div>
            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn}
                 onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default MidTwentyFive