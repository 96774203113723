import React, { useEffect } from 'react'

import styles from './HandleTrafficSituation.module.css'

import { addToQuizForHandleTrafficSituation, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const HandleTrafficSituation = () => {

    const handleTrafficSituation = useSelector((state) => state.quiz.handleTrafficSituation)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(8))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForHandleTrafficSituation(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/improve-mental-wellbeing' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.handle__stressful__situation__wrap}>
            <h1 className={styles.sec__title}>Imagine you're running late for an important meeting and get cut off in traffic. How do you typically react in this kind of stressful situation?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${handleTrafficSituation === `I feel extremely overwhelmed and it's hard for me to think clearly or respond effectively` ? styles.active : ''}`} 
                onClick={ e => {onGoalSelected(e, `I feel extremely overwhelmed and it's hard for me to think clearly or respond effectively`)}}>
                    <div className={styles.goal__item__title}>
                        I feel extremely overwhelmed and it's hard for me to think clearly or respond effectively
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleTrafficSituation === 'I feel somewhat stressed and it takes me some time to regain my composure' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'I feel somewhat stressed and it takes me some time to regain my composure')}}>
                    <div className={styles.goal__item__title}>
                        I feel somewhat stressed and it takes me some time to regain my composure
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleTrafficSituation === 'I handle these situations okay, but I could use some strategies to manage stress better' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'I handle these situations okay, but I could use some strategies to manage stress better')}}>
                    <div className={styles.goal__item__title}>
                        I handle these situations okay, but I could use some strategies to manage stress better
                    </div>
                </div>
                <div className={`${styles.goal__item} ${handleTrafficSituation === 'I usually stay calm and composed, even in difficult situations.' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'I usually stay calm and composed, even in difficult situations.')}}>
                    <div className={styles.goal__item__title}>
                        I usually stay calm and composed, even in difficult situations.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HandleTrafficSituation