import React, {useEffect} from 'react'

import styles from './SelfCareTiming.module.css'

import OkayImage from '../../images/ok.png'
import ThumbsUpImage from '../../images/thumbs-up.png'
import BicepsImage from '../../images/biceps.png'
import MedalImage from '../../images/medal.png'

import { addToQuizForSelfCareTiming, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const SelfCareTiming = () => {
    const selfCareTiming = useSelector((state) => state.quiz.selfCareTiming)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(10))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForSelfCareTiming(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/control-important-things' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.selfCareTiming__wrap}>
            <h1 className={styles.sec__title}>When you do find time for these activities, how often do they get overshadowed or interrupted by work, chores, or other responsibilities?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${selfCareTiming === `Almost always - It's hard to focus on myself without interruptions` ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, `Almost always - It's hard to focus on myself without interruptions`)}}>
                    <div className={styles.goal__item__title}>
                        Almost always - It's hard to focus on myself without interruptions
                    </div>
                    <div className={styles.activity__img}>
                        <img src={OkayImage} alt="Beginner" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${selfCareTiming === 'Often - My self-care time often gets compromised' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Often - My self-care time often gets compromised')}}>
                    <div className={styles.goal__item__title}>
                        Often - My self-care time often gets compromised
                    </div>
                    <div className={styles.activity__img}>
                        <img src={ThumbsUpImage} alt="Intermidiate" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${selfCareTiming === 'Sometimes - I manage to have uninterrupted time occasionally' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Sometimes - I manage to have uninterrupted time occasionally')}}>
                    <div className={styles.goal__item__title}>
                        Sometimes - I manage to have uninterrupted time occasionally
                    </div>
                    <div className={styles.activity__img}>
                        <img src={BicepsImage} alt="Advanced" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${selfCareTiming === 'Rarely - I generally manage to keep this time for myself undisturbed' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Rarely - I generally manage to keep this time for myself undisturbed')}}>
                    <div className={styles.goal__item__title}>
                        Rarely - I generally manage to keep this time for myself undisturbed
                    </div>
                    <div className={styles.activity__img}>
                        <img src={MedalImage} alt="Advanced" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelfCareTiming