import React, { useState, useEffect } from 'react'

import styles from './SeekMeditationBenefit.module.css'

import { addToQuizForSeekMeditationBenefit, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const SeekMeditationBenefit = () => {

    const [seekMeditationBenefitArr, setSeekMeditationBenefitArr] = useState([])

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(7))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, targetZone){
        if(seekMeditationBenefitArr.includes(targetZone)){
            let tagerZoneFilteredArr = seekMeditationBenefitArr.filter(n => n !== targetZone)
            setSeekMeditationBenefitArr(tagerZoneFilteredArr)
        } else {
            setSeekMeditationBenefitArr([...seekMeditationBenefitArr, targetZone])
        }
    }

    function onContinueClicked(e){
        dispatch(addToQuizForSeekMeditationBenefit(seekMeditationBenefitArr))

        setTimeout(() => {
            navigate('/quiz/handle-traffic-situation' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.seek__meditation__benefit__wrap}>
            <h1 className={styles.sec__title}>Choose the primary benefit you're seeking from meditation</h1>

            <div className={styles.focus__wrap}>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Stress Reduction') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Stress Reduction')}}>
                    <div className={styles.goal__item__title}>
                        Stress Reduction
                    </div>
                </div>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Improved Focus & Clarity') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Improved Focus & Clarity')}}>
                    <div className={styles.goal__item__title}>
                        Improved Focus & Clarity
                    </div>
                </div>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Increased Self-Awareness') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Increased Self-Awareness')}}>
                    <div className={styles.goal__item__title}>
                        Increased Self-Awareness
                    </div>
                </div>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Spiritual Growth') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Spiritual Growth')}}>
                    <div className={styles.goal__item__title}>
                        Spiritual Growth
                    </div>
                </div>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Emotional Balance') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Emotional Balance')}}>
                    <div className={styles.goal__item__title}>
                        Emotional Balance
                    </div>
                </div>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Improved Relationships') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Improved Relationships')}}>
                    <div className={styles.goal__item__title}>
                        Improved Relationships
                    </div>
                </div>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Better Sleep') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Better Sleep')}}>
                    <div className={styles.goal__item__title}>
                        Better Sleep
                    </div>
                </div>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Enhanced Creativity') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Enhanced Creativity')}}>
                    <div className={styles.goal__item__title}>
                        Enhanced Creativity
                    </div>
                </div>
                <div className={`${styles.goal__item} ${seekMeditationBenefitArr.includes('Physical Health (e.g., lower blood pressure, reduced pain)') ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Physical Health (e.g., lower blood pressure, reduced pain)')}}>
                    <div className={styles.goal__item__title}>
                        Physical Health (e.g., lower blood pressure, reduced pain)
                    </div>
                </div>
            </div>

            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn} disabled={seekMeditationBenefitArr.length < 1}
                 onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default SeekMeditationBenefit