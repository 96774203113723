import React, { useEffect } from 'react'

import styles from './ControlImportantThings.module.css'
import OneImage from '../../images/one.png'
import { addCurrentStep, addToQuizForControlImportantThings, addToQuizForPssScoreForQuestionOne } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const ControlImportantThings = () => {

    const controlImportantThings = useSelector((state) => state.quiz.controlImportantThings)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(11))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected, pssScore){
        dispatch(addToQuizForControlImportantThings(quizTypeSelected))
        dispatch(addToQuizForPssScoreForQuestionOne(pssScore))

        setTimeout(() => {
            navigate('/quiz/difficulty-piling' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.ControlImportantThings__wrap}>
            <h1 className={styles.sec__title}>Over the last month, how often have you felt that you were unable to control the important things in your life?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${controlImportantThings === 'Never' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Never', 0)}}>
                    <div className={styles.goal__item__title}>
                        Never
                    </div>
                </div>

                <div className={`${styles.goal__item} ${controlImportantThings === 'Almost Never' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Almost Never', 5)}}>
                    <div className={styles.goal__item__title}>
                        Almost Never
                    </div>
                </div>

                <div className={`${styles.goal__item} ${controlImportantThings === 'Sometimes' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Sometimes', 10)}}>
                    <div className={styles.goal__item__title}>
                        Sometimes
                    </div>
                </div>

                <div className={`${styles.goal__item} ${controlImportantThings === 'Fairly Often' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Fairly Often', 15)}}>
                    <div className={styles.goal__item__title}>
                        Fairly Often
                    </div>
                </div>

                <div className={`${styles.goal__item} ${controlImportantThings === 'Very Often' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Very Often', 20)}}>
                    <div className={styles.goal__item__title}>
                        Very Often
                    </div>
                </div>
            </div>



            <div className={styles.bmi__note__wrap}>
                <div className={styles.bmi__note__img}>
                    <img src={OneImage} alt="" />
                </div>
                
                <div className="">
                    <div className={styles.bmi__note__title}>Calculating your Perceived Stress Score (PSS)</div>
                    <div className={styles.bmi__note__sub__title}>PSS is the most widely used psychological instrument for measuring factor for development of or the prevalence of several health issues.</div>
                </div>
            </div>
        </div>
    )
}

export default ControlImportantThings