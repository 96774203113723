import React, { useEffect, useState } from 'react'

import styles from './StressReductionPlan.module.css'
// import GraphImage from '../../images/graph.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { addCurrentStep } from '../../reducers/quiz'
import RechartLineGraph from '../rechart-line-graph/RechartLineGraph'

const StressReductionPlan = () => {
    const [futureDate, setFutureDate] = useState('')
    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(20))
        setFutureDate(dateAfterFourWeeks)
    }, [setFutureDate, dispatch]);

    function onContinueClicked(e){
        setTimeout(() => {
            navigate('/quiz/statement-immediate-problem' + queryParams)
        }, 500);
    }

    function dateAfterFourWeeks(){
        let currentDate = new Date();
        let futureDate = new Date(currentDate.getTime() + (28 * 24 * 60 * 60 * 1000));
        let shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let formattedDate = shortMonthNames[futureDate.getMonth()] + " " + futureDate.getDate() + ", " + futureDate.getFullYear();
        return formattedDate
    }

    return (
        <div className={styles.stress__reduction__wrap}>
            <div className={styles.stress__reduction__main}>
                <h1 className={styles.sec__title}>
                    The one and only plan you'll ever need to <span className={styles.green__highlight}>enhance your mindfulness and reduce stress.</span>
                </h1>

                <div className={styles.get__in__shape__wrap}>
                    <div className={styles.text}>According to the information you've provided us, you'll achieve your desired mindfulness level and stress reduction by</div>
                    <div className={styles.text__main}>{futureDate}</div>
                    <div className={styles.text}>Get ready to amaze everyone with your calmness and control</div>
                </div>

                {/* <div className={styles.graph__container}>
                    <img src={GraphImage} alt="GraphImage" />
                </div> */}

                <div className={styles.graph__container__main}>
                    <RechartLineGraph></RechartLineGraph>
                </div>
                
            </div>
        
            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn}
                 onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default StressReductionPlan