import React, {useEffect} from 'react'

import styles from './ImproveMentalWellbeing.module.css'
import OkayImage from '../../images/ok.png'
import ThumbsUpImage from '../../images/thumbs-up.png'
import BicepsImage from '../../images/biceps.png'
import MedalImage from '../../images/medal.png'

import { addToQuizForImproveMentalWellbeing, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const ImproveMentalWellbeing = () => {
    const improveMentalWellbeing = useSelector((state) => state.quiz.improveMentalWellbeing)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(9))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForImproveMentalWellbeing(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/self-care-timing' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.improve__mental__wellbeing__wrap}>
            <h1 className={styles.sec__title}>How often do you intentionally set aside time for activities that improve your mental wellbeing?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${improveMentalWellbeing === 'Rarely or never - I struggle to find time for myself' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Rarely or never - I struggle to find time for myself')}}>
                    <div className={styles.goal__item__title}>
                        Rarely or never - I struggle to find time for myself
                    </div>
                    <div className={styles.activity__img}>
                        <img src={OkayImage} alt="Beginner" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${improveMentalWellbeing === `A few times a week - but it's usually squeezed in between other tasks` ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, `A few times a week - but it's usually squeezed in between other tasks`)}}>
                    <div className={styles.goal__item__title}>
                        A few times a week - but it's usually squeezed in between other tasks
                    </div>
                    <div className={styles.activity__img}>
                        <img src={ThumbsUpImage} alt="Intermidiate" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${improveMentalWellbeing === 'Daily - but for less than 30 minutes' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Daily - but for less than 30 minutes')}}>
                    <div className={styles.goal__item__title}>
                        Daily - but for less than 30 minutes
                    </div>
                    <div className={styles.activity__img}>
                        <img src={BicepsImage} alt="Advanced" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${improveMentalWellbeing === 'Daily - for more than 30 minutes' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'Daily - for more than 30 minutes')}}>
                    <div className={styles.goal__item__title}>
                        Daily - for more than 30 minutes
                    </div>
                    <div className={styles.activity__img}>
                        <img src={MedalImage} alt="Advanced" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImproveMentalWellbeing