import React, { useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import styles from './SocialProof.module.css'
import SocialProofImage from '../../images/social-proof.png'

import HeaderHome from '../../components/header-home/HeaderHome'

const SocialProof = () => {
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
  const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";

  const navigate = useNavigate()

  function onSocialProofContinue() {
    navigate('/quiz/motivate-goal' + queryParams)
  }

  useEffect(() => {
    const cleanUp = setTimeout(() => {
      window.mixpanel.track("social-proof", {
        'type': 'viewcontent'
      })
      window.mixpanel.track('ViewContent', {
        'type':'viewcontent'
      })
      window.mixpanel.track_pageview();
    }, 100);
    // Clean up
    return () => clearTimeout(cleanUp);
  }, []);

  return (

    <div>
      <HeaderHome></HeaderHome>

      <section className={`${styles.social__proof__sec}`}>
        <div className={styles.social__proof__main}>
          <h1 className={styles.sec__title}>250 thousand</h1>
          <h1 className={styles.sec__sub__title}>users have chosen us</h1>

          <div className={styles.social__proof__action__wrap}>
            <img className={styles.social__proof__img} src={SocialProofImage} alt="Social Proof" />

            <div className={styles.shadow__btn}>
              <button className={styles.gender__select__btn} onClick={onSocialProofContinue}>Continue</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SocialProof