import React, { useEffect } from 'react'

import styles from './ScreenTime.module.css'

import { addToQuizForScreenTime, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const ScreenTime = () => {

    const screenTime = useSelector((state) => state.quiz.screenTime)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(18))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForScreenTime(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/handle-stress-situation' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.handle__stressful__situation__wrap}>
            <h1 className={styles.sec__title}>What's your screen time like?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${screenTime === `I spend most of my day in front of a screen` ? styles.active : ''}`} 
                onClick={ e => {onGoalSelected(e, `I spend most of my day in front of a screen`)}}>
                    <div className={styles.goal__item__title}>
                        I spend most of my day in front of a screen
                    </div>
                </div>
                <div className={`${styles.goal__item} ${screenTime === 'I spend about half of my day in front of a screen' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'I spend about half of my day in front of a screen')}}>
                    <div className={styles.goal__item__title}>
                        I spend about half of my day in front of a screen
                    </div>
                </div>
                <div className={`${styles.goal__item} ${screenTime === 'I spend a few hours a day in front of a screen' ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, 'I spend a few hours a day in front of a screen')}}>
                    <div className={styles.goal__item__title}>
                        I spend a few hours a day in front of a screen
                    </div>
                </div>
                <div className={`${styles.goal__item} ${screenTime === `I spend an hour or less a day in front of a screen` ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, `I spend an hour or less a day in front of a screen`)}}>
                    <div className={styles.goal__item__title}>
                        I spend an hour or less a day in front of a screen
                    </div>
                </div>
                <div className={`${styles.goal__item} ${screenTime === `I rarely spend time in front of a screen` ? styles.active : ''}`}
                 onClick={ e => {onGoalSelected(e, `I rarely spend time in front of a screen`)}}>
                    <div className={styles.goal__item__title}>
                        I rarely spend time in front of a screen
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScreenTime