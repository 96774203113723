import React, {useEffect} from 'react'

import styles from './MindfulnessPracticeLevel.module.css'
import BeginnerImage from '../../images/activity-beginner.png'
import IntermediateImage from '../../images/activity-intermidiate.png'
import AdvancedImage from '../../images/activity-advanced.png'

import { addToQuizForMindfulnessPracticeLevel, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const MindfulnessPracticeLevel = () => {
    const mindfulnessPracticeLevel = useSelector((state) => state.quiz.mindfulnessPracticeLevel)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(6))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForMindfulnessPracticeLevel(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/seek-meditation-benefit' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.activity__level__wrap}>
            <h1 className={styles.sec__title}>What is your current mindfulness practice level?</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${mindfulnessPracticeLevel === 'I seldom or never practice mindfulness or meditation' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'I seldom or never practice mindfulness or meditation')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.medium__text}>
                            Beginner
                        </div>
                        <div className={styles.small__text}>
                            I seldom or never practice mindfulness or meditation
                        </div>
                    </div>
                    <div className={styles.activity__img}>
                        <img src={BeginnerImage} alt="Beginner" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${mindfulnessPracticeLevel === 'I engage in mindfulness exercises or meditate 2-3 days per week' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'I engage in mindfulness exercises or meditate 2-3 days per week')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.medium__text}>
                            Intermediate
                        </div>
                        <div className={styles.small__text}>
                            I engage in mindfulness exercises or meditate 2-3 days per week
                        </div>
                    </div>
                    <div className={styles.activity__img}>
                        <img src={IntermediateImage} alt="Intermediate" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${mindfulnessPracticeLevel === 'I practice mindfulness or meditate daily or nearly every day' ? styles.active : ''}`}
                 onClick={e => {onGoalSelected(e, 'I practice mindfulness or meditate daily or nearly every day')}}>
                    <div className={styles.activity__text}>
                        <div className={styles.medium__text}>
                            Advanced
                        </div>
                        <div className={styles.small__text}>
                            I practice mindfulness or meditate daily or nearly every day
                        </div>
                    </div>
                    <div className={styles.activity__img}>
                        <img src={AdvancedImage} alt="Advanced" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MindfulnessPracticeLevel