import React, { useEffect } from 'react'

import styles from './MindfulnessUpdates.module.css'

import { useDispatch } from 'react-redux'
import { isShowQuizHeader } from '../../reducers/quiz'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Header from '../header-home/HeaderHome'

const MindfulnessUpdates = () => {
    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        dispatch(isShowQuizHeader(false))

        return () => {
            dispatch(isShowQuizHeader(true))
        }
    })

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onContinueClicked(e){
        setTimeout(() => {
            navigate('/quiz/stress-score' + queryParams)
        }, 500);
    }

    return (
        <div>
            <Header></Header>

            <div className={styles.mindfulness__updates__main}>
                <h1 className={styles.sec__title}>Do you want to receive emails with <span className={styles.green__highlight}>Mindfulness tips</span> and updates and product updates?</h1>
            </div>

            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn}
                 onClick={onContinueClicked}>SURE, I'M IN</button>

                <Link to={`/quiz/stress-score${queryParams}`} className={styles.skip__link}>I DON’T WANT TO RECEIVE TIPS OR UPDATES</Link>
            </div>
        </div>
    )
}

export default MindfulnessUpdates