import React, { useEffect } from 'react'

import styles from './CurrentMindState.module.css'

import ConstantlyOverwhelmedImage from '../../images/ConstantlyOverwhelmed.png'
import OccasionallyStressedImage from '../../images/OS2.png'
import LookingForFocusImage from '../../images/Calm.png'
import SeekingGrowthImage from '../../images/lastikr.png'

import ConstantlyOverwhelmedMaleImage from '../../images/ConstantlyOverwhelmedMale.png'
import OccasionallyStressedMaleImage from '../../images/OS2Male.png'
import LookingForFocusMaleImage from '../../images/CalmMale.png'
import SeekingGrowthMaleImage from '../../images/lastikrMale.png'

import { addToQuizForCurrentMindState, addCurrentStep } from '../../reducers/quiz'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

const CurrentMindState = () => {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : "";

    const currentMindState = useSelector((state) => state.quiz.currentMindState)
    const gender = useSelector((state) => state.quiz.gender)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addCurrentStep(2))
    });

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    function onGoalSelected(e, quizTypeSelected){
        dispatch(addToQuizForCurrentMindState(quizTypeSelected))

        setTimeout(() => {
            navigate('/quiz/mind-state-you-want' + queryParams)
        }, 500);
    }

    return (
        <div className={styles.current__mind__state__wrap}>
            <h1 className={styles.sec__title}>Choose your current state of mind</h1>

            <div className={styles.goals__list}>
                <div className={`${styles.goal__item} ${currentMindState === 'Constantly Overwhelmed' ? styles.active : ''}`} 
                onClick={ e => {onGoalSelected(e, 'Constantly Overwhelmed')}}>
                    <div className={styles.goal__item__title}>
                        Constantly Overwhelmed
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? ConstantlyOverwhelmedImage : ConstantlyOverwhelmedMaleImage} alt="Constantly Overwhelmed" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${currentMindState === 'Occasionally Stressed' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'Occasionally Stressed')}}>
                    <div className={styles.goal__item__title}>
                        Occasionally Stressed
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? OccasionallyStressedImage: OccasionallyStressedMaleImage} alt="Occasionally Stressed" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${currentMindState === 'Generally Calm, But Looking for Focus' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'Generally Calm, But Looking for Focus')}}>
                    <div className={styles.goal__item__title}>
                        Generally Calm, But Looking for Focus
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? LookingForFocusImage : LookingForFocusMaleImage} alt="Generally Calm, But Looking for Focus" />
                    </div>
                </div>
                <div className={`${styles.goal__item} ${currentMindState === 'Already Practicing Mindfulness, Seeking Growth' ? styles.active : ''}`}
                onClick={ e => {onGoalSelected(e, 'Already Practicing Mindfulness, Seeking Growth')}}>
                    <div className={styles.goal__item__title}>
                        Already Practicing Mindfulness, Seeking Growth
                    </div>
                    <div className={styles.goal__item__img}>
                        <img src={gender === 'female' ? SeekingGrowthImage : SeekingGrowthMaleImage} alt="Already Practicing Mindfulness, Seeking Growth" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentMindState