import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isShowQuizHeader } from '../../reducers/quiz'

import styles from './MidTwentyOne.module.css'
import HealthyImage from '../../images/healthy.png'
import { useNavigate, useSearchParams } from 'react-router-dom'

const MidTwentyOne = () => {
    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(isShowQuizHeader(false))

        return () => {
            dispatch(dispatch(isShowQuizHeader(true)))
        }
    })

    function onContinueClicked(e){
        setTimeout(() => {
            navigate('/quiz/statement-multitask' + queryParams)
        }, 500);
    }
    

    return (
        <div className={`${styles.midTwentyOne__wrap} is__full-height`}>
            <div className={styles.midTwentyOne__main}>
                <div className={styles.banner__image}>
                    <img src={HealthyImage} alt="Healthy" />
                </div>
                <h1 className={styles.title}>Your mindset and you.</h1>
                <h2 className={styles.text}>"HOW we think is as significant as WHAT we think about. Breaking the negative thought patterns we have developed can help us reduce stress and improve overall well-being without a major lifestyle overhaul."</h2>
            </div>
            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn}
                 onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default MidTwentyOne