import React from 'react'

import { Link } from 'react-router-dom'

import './HeaderHome.css'
import logo from '../../images/logo.png'
// import { useSelector } from 'react-redux'

const Header = () => {
  // const showLogin = useSelector(state => state.quiz.showLogin)

  return (
    <div className="header">
        <div className="logo__header">
          <Link to="/">
            <img className="logo__header-img" src={logo} alt="logo" />
          </Link>
        </div>
        
        {/* {pathName === '/social-proof' ? '' : <Link to="/" className="login__anchor">Log In</Link>} */}

        {/* { showLogin ? <Link to="/" className="login__anchor">Log In</Link> : '' } */}
    </div>
  )
}

export default Header