import React, { useEffect, useState } from 'react'

import styles from './QuizEmail.module.css'

import RoundTickImage from '../../images/round-tick.svg'
import PadlockImage from '../../images/padlock.svg'

import { useDispatch, useSelector } from 'react-redux'
import { addToQuizForQuizReferenceId, addToQuizForQuizEmail, addCurrentStep } from '../../reducers/quiz'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import axios from 'axios';
import { useCallback } from 'react'

const QuizEmail = () => {
    const [emailValid, setEmailValid] = useState('')

    const [userEmail, setUserEmail] = useState('')

    const pssStrength = useSelector(state => state.quiz.pssStrength)
    const goal = useSelector(state => state.quiz.goal)
    const gender = useSelector(state => state.quiz.gender)

    const [searchParams, setSearchParams] = useSearchParams() // eslint-disable-line
    const queryParams = searchParams.toString() ? `?${searchParams.toString()}` : ""

    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(()=>{
        dispatch(addCurrentStep(26))
    })

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])
    

    function checkEmailValidity(value){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)){ //eslint-disable-line
          setEmailValid('true')
          return
        }
        
        setEmailValid('false')
    }

    function onEmailInputChanged(e){
        checkEmailValidity(e.target.value)
        setUserEmail(e.target.value)
        console.log(emailValid)
    }

    function onContinueClicked(e) {
        continueStep()
    }

    function handleFormSubmit(e) {
        e.preventDefault()
        continueStep()
    }

    function continueStep (){
        if(emailValid === 'true'){
            setTimeout(() => {
                dispatch(addToQuizForQuizEmail(userEmail))
                postQuizData()
                navigate('/quiz/mindfulness-updates' + queryParams)
            }, 500);
        }
        return
    }

    const quiz = {
        quizEmail: userEmail,
        pssStrength: pssStrength,
        goal: goal,
        gender: gender
    }

    const postQuizData = useCallback( async() => {

        axios.post('https://theinscentswaterfall.com/experiment/supratim/med-app/api/add-quiz', {
            quiz: quiz
        })
        .then(function (response) {
            console.log(response)
            if (response.data.response && response.data.response === "success" && response.data.reference_id) {
                console.log(response.data.reference_id)
                return dispatch(addToQuizForQuizReferenceId(`${response.data.reference_id}`))
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    }, [quiz])// eslint-disable-line

   

    return (
        <div>
            <form onSubmit={e => handleFormSubmit(e)}>
                <div className={styles.sec__email__wrap}>
                    <h1 className={styles.sec__title}>Enter your email to receive your <span>Personalized Mindfulness</span> plan!</h1>

                    <div className={styles.email__input__container}>
                        <svg className={styles.padlock__image} width="18" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.74 1.143l-.023-.041c.185.35.283.699.283 1.66v8.477c0 .96-.098 1.308-.283 1.66a1.941 1.941 0 01-.8.813c-.345.188-.688.288-1.632.288H2.715c-.944 0-1.287-.1-1.632-.288a1.94 1.94 0 01-.8-.814C.098 12.548 0 12.2 0 11.238V2.762c0-.922.09-1.28.261-1.617a.536.536 0 00.178.311l8.082 6.96a.732.732 0 00.958 0l8.082-6.96a.539.539 0 00.178-.312zM15.284 0c.775 0 1.145.067 1.443.195l-.07.028-.083.05-7.137 5.323a.731.731 0 01-.876 0L1.425.273a.525.525 0 00-.153-.079C1.57.067 1.94 0 2.715 0h12.57z" fill="#8696A6"></path>
                        </svg>
                        
                        <input className={`${styles.email__input} ${emailValid === 'false' ? styles.invalid : ''}`}
                         type="email" placeholder='Your email'
                         onChange={e => onEmailInputChanged(e)}/>

                        { emailValid === 'true' ? <img className={styles.round__tick__image} src={RoundTickImage} alt="RoundTickImage" /> : ''}
                        { emailValid === 'false' ? <span className={styles.email__invalid__icon}>!</span> : ''}
                    </div>

                    <div className={styles.email__validity__alert__container}>
                        { emailValid === 'true' ? <span className={styles.email__valid__alert}>Perfect</span> : ''}
                        { emailValid === 'false' ? <span className={styles.email__invalid__alert}>Email is invalid</span> : ''}
                    </div>

                    <div className={styles.lock__note__wrap}>
                        <div className={styles.padlock__img}>
                            <img src={PadlockImage} alt="Padlock" />
                        </div>
                        <div className={styles.lock__note}>
                            We respect your privacy. Your email can make the advertising experience more relevant. <Link>Privacy Policy.</Link>
                        </div>
                    </div>
                </div>
            </form>
            <div className={styles.shadow__btn}>
                <button className={styles.gender__select__btn} disabled={emailValid === 'true' ? false : true}
                    onClick={e => {onContinueClicked(e)}}>Continue</button>
            </div>
        </div>
    )
}

export default QuizEmail